export const initialStateMixerFeatures = () => {
  return {
    mixer_material: {
      name: "mixer_material",
      value: "Acero inox. 304, 316",
    },
    mixer_type: {
      name: "mixer_type",
      value: "Vertical centrado",
    },
    angle: {
      name: "angle",
      value: 0,
    },
  };
};
