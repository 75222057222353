import { reports_constants } from "../constants/reports.constants";

export const reportsReducer = (
  state = {
    reports_data: { results: [], users: [] },
    query_params: {
      show_users: true,
    },
    current_row_data: { id_calculation: "", display_pdf: false },
    loading_pdf: false,
    loading_reports: false,
    delete: {
      loading: false,
      error: false,
      message: "",
    },
  },
  action
) => {
  switch (action.type) {
    case reports_constants.REPORTS_REQUEST:
      delete state.error_reports;
      return { ...state, loading_reports: true };

    case reports_constants.REPORTS_REQUEST_SUCCESS:
      let aux = {
        ...action.payload,
        users: action.payload.users
          ? action.payload.users
          : state.reports_data.users,
      };
      return {
        ...state,
        loading_reports: false,
        reports_data: aux,
        query_params: {
          ...state.query_params,
          show_users: false,
        },
      };

    case reports_constants.REPORTS_REQUEST_FAIL:
      return {
        ...state,
        loading_reports: false,
        error_reports: action.payload,
      };

    case reports_constants.REPORTS_PDF_REQUEST:
      return { ...state, loading_pdf: true };

    case reports_constants.REPORTS_PDF_REQUEST_SUCCESS:
      return {
        ...state,
        loading_pdf: false,
        current_row_data: { ...state.current_row_data, display_pdf: true },
      };

    case reports_constants.REPORTS_PDF_REQUEST_FAIL:
      return { ...state, loading_pdf: false, error_pdf: action.payload };

    case reports_constants.SET_CURRENT_ROW_ID:
      return {
        ...state,
        current_row_data: {
          ...state.current_row_data,
          id_calculation: action.payload,
        },
      };

    case reports_constants.DELETE_CALCULATION_BY_ID_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      };
    case reports_constants.DELETE_CALCULATION_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          error: false,
        },
      };
    case reports_constants.DELETE_CALCULATION_BY_ID_REQUEST_FAIL:
      return {
        ...state,
        delete: {
          loading: false,
          error: true,
          message: action.payload,
        },
      };

    default:
      return state;
  }
};
