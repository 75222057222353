import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { reports_constants } from "@/constants/reports.constants";
import Pagination from "@/components/Pagination";
import MenuActions from "@/components/MenuActions";
import moment from "moment";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Id",
    can_sort: true,
  },
  {
    id: "created_by",
    numeric: true,
    disablePadding: false,
    label: "Creado por",
    can_sort: true,
  },
  {
    id: "detail_model",
    numeric: true,
    disablePadding: false,
    label: "Modelo ",
    can_sort: true,
  },
  {
    id: "create_time",
    numeric: true,
    disablePadding: false,
    label: "Fecha de creación",
    can_sort: true,
  },
  {
    id: "modified_by",
    numeric: true,
    disablePadding: false,
    label: "Últ. mod. por",
    can_sort: true,
  },
  {
    id: "modification",
    numeric: true,
    disablePadding: false,
    label: "Últ. mod.",
    can_sort: true,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Acción",
    can_sort: false,
  },
];

function EnhancedTableHead(props) {
  const [orderBy, setOrderBy] = React.useState();
  const [order, setOrder] = React.useState("asc");
  const sortHandler = (id) => {
    if (orderBy === id) {
      setOrder(order === "asc" ? "desc" : "asc");
    }
    setOrderBy(id);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.can_sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => sortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default function ReportsTable() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reports = useSelector((state) => state.reports);
  const { reports_data, loading_reports, error_reports, current_row_data } =
    reports;

  const handleClick = React.useCallback(
    (event, id_calculation) => {
      setAnchorEl(event.currentTarget);
      dispatch({
        type: reports_constants.SET_CURRENT_ROW_ID,
        payload: id_calculation,
      });
    },
    [dispatch]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    reports_data.current_page > 0
      ? Math.max(
          0,
          (1 + reports_data.current_page - 1) * reports_data.per_page -
            reports_data.count
        )
      : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Cálculos
          </Typography>
        </Toolbar>

        {reports_data && (
          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead rowCount={reports_data.count} />
                <TableBody>
                  {loading_reports ? (
                    <TableRow
                      style={{
                        height: 47 * 11,
                      }}
                    >
                      <TableCell colSpan={7}>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            // margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {error_reports ? (
                        <TableRow
                          style={{
                            height: 47 * 11,
                          }}
                        >
                          <TableCell colSpan={7}>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                // margin: "0 auto",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              <Alert severity="error">{error_reports}</Alert>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {reports_data.results.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  hover
                                  // onClick={(event) => handleClick(event, row.name)}
                                  // role="checkbox"
                                  // aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.name}
                                  // selected={isItemSelected}
                                >
                                  <TableCell
                                    // component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="normal"
                                    align="center"
                                    sx={(theme) => ({
                                      a: {
                                        fontWeight: 600,
                                        textDecoration: "none",
                                        color: theme.palette.text.brand,
                                        width: "100%",
                                        "&:hover": {
                                          // color: "#447e9b",
                                          borderBottom: `1px solid ${theme.palette.text.brand}`,
                                        },
                                      },
                                    })}
                                  >
                                    <Link
                                      to={`/calculation/${row.id_calculation}`}
                                    >
                                      {row.id_calculation}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.user ? row.user.username : <>--</>}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.detail_model}
                                  </TableCell>
                                  <TableCell align="right">
                                    {moment(row.create_time).calendar({
                                      sameElse: "MMM Do Y,  h:mm A",
                                    })}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.last_modified_by ? (
                                      row.last_modified_by.username
                                    ) : (
                                      <>--</>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {moment(row.last_modification).calendar({
                                      sameElse: "MMM Do Y,  h:mm A",
                                    })}
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      size="small"
                                      aria-controls="row_actions"
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        handleClick(e, row.id_calculation);
                                      }}
                                      color="inherit"
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 47 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination />
          </>
        )}
        <MenuActions
          id="row_actions"
          id_calculation={current_row_data.id_calculation}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        />
      </Paper>
    </Box>
  );
}
