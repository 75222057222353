import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@/components/Table";
import { useSelector } from "react-redux";

function GearUnits(props) {
  const calculation = useSelector((state) => state.calculation);
  const { table_gear_units } = calculation;
  const { content } = props;
  let cont = -1;
  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="table vertical gear units">
        <TableHead>
          <TableRow>
            <TableCell colSpan={content.columns_title.length} align="center">
              {content.title}
            </TableCell>
          </TableRow>

          <TableRow>
            {content.columns_title.map((title) => {
              return (
                <TableCell padding="none" align="center">
                  <span>{title}</span>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={(theme) => ({
            tr: {
              "&:hover": {
                backgroundColor: theme.palette.action.disabled,
              },
            },
          })}
        >
          {content.content.map((row, index) => {
            if (row.red != "") {
              cont += 1;
            }
            return (
              <TableRow>
                <TableCell
                  padding="none"
                  sx={(theme) => ({
                    padding: "5px 0",
                    height: "25px",
                    borderRight:
                      theme.palette.mode === "dark"
                        ? "1px solid #333333"
                        : "1px solid #f5f5f5",
                  })}
                  align="center"
                >
                  <span>{row.axis}</span>
                </TableCell>

                <TableCell
                  padding="none"
                  align="center"
                  sx={(theme) => ({
                    padding: "5px 0",
                    height: "25px",
                    borderRight:
                      theme.palette.mode === "dark"
                        ? "1px solid #333333"
                        : "1px solid #f5f5f5",
                  })}
                >
                  <span>
                    {table_gear_units[content.state].value !== "" &&
                      row.red !== "" && (
                        <>{table_gear_units[content.state].value[cont]}</>
                      )}
                  </span>
                </TableCell>
                <TableCell
                  padding="none"
                  align="center"
                  sx={(theme) => ({
                    padding: "5px 0",
                    height: "25px",
                    borderRight:
                      theme.palette.mode === "dark"
                        ? "1px solid #333333"
                        : "1px solid #f5f5f5",
                  })}
                >
                  <span>{row.red}</span>
                </TableCell>
                <TableCell
                  padding="none"
                  align="center"
                  sx={(theme) => ({
                    padding: "5px 0",
                    height: "25px",
                  })}
                >
                  <span>{row.flange}</span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GearUnits;
