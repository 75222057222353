import React from "react";
import Select from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/system";
const StyledInputElement = styled(InputBase)(
  ({ theme }) => `
  width: 100%;
  font-size: inherit;
  color: inherit;
  border: none;
  background: inherit;
  outline: none;
  transition: all 100ms ease;
  border: 2px solid transparent;
  &:focus {
      border: 2px solid ${
        theme.palette.mode === "dark" ? theme.palette.text.primary : "black"
      };
  }
  `
);
export const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  return (
    <StyledInputElement
      components={{
        Input: StyledInputElement,
      }}
      {...props}
      ref={ref}
    />
  );
});
function CalcInputForSelect(props) {
  const [value, setValue] = React.useState(
    props.state_value ? props.state_value : ""
  );

  React.useEffect(() => {
    setValue(props.state_value); // Need to fix this react issue
  }, [props.state_value]);
  return (
    <CustomInput
      {...props}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
}

const CalcSelect = (props) => {
  return (
    <Select
      sx={{ border: "unset", outline: "none" }}
      input={<CalcInputForSelect />}
      {...props}
    >
      {props?.children}
    </Select>
  );
};

export default CalcSelect;
