import { calculation_constants } from "../constants/calculation.constants";
import {
  makeCalculationRequest,
  transformCalculationResponse,
} from "../helpers/handleCalculationRequest";
import { getGroupOfMobileTypeChange } from "../helpers/onMobileTypeChange";
import {
  calculateChangeService,
  getCalculationByIdService,
  createCalculationService,
  updateCalculationService,
  getCalculationLogsService,
} from "../services/calculation.service";
import { getPDFDownload } from "@/actions/reports.actions";

export const resetCalculation = () => async (dispatch) => {
  dispatch({
    type: calculation_constants.RESET_CALCULATION,
  });
};

export const addPropeller = () => async (dispatch, getState) => {
  const {
    calculation: { table_propeller },
  } = getState();

  if (table_propeller.length < 5) {
    dispatch({
      type: calculation_constants.ADD_PROPELLER,
    });
    dispatch(
      calculateChange({
        belongs_to: "special_fields",
        name: "add_propeller",
        position: 0,
        value: 0,
      })
    );
  }
};

export const deletePropeller = (position) => async (dispatch, getState) => {
  const {
    calculation: { table_propeller },
  } = getState();
  if (table_propeller.length > 1) {
    dispatch({
      type: calculation_constants.DELETE_PROPELLER,
      payload: position,
    });
    dispatch(
      calculateChange({
        belongs_to: "special_fields",
        name: "delete_propeller",
        position: 0,
        value: 0,
      })
    );
  }
};

export const disablePropeller = (position) => async (dispatch, getState) => {
  const {
    calculation: { table_propeller },
  } = getState();

  // It doesn't make sense to disable if we only have one impeller, so we return
  if (table_propeller.length < 2) {
    return;
  }
  // only disable when is more than one propeller enabled
  let count = 0;
  for (let i in table_propeller) {
    if (count == 2) {
      break;
    }
    if (table_propeller[i].is_enabled) {
      count++;
    }
  }
  if (count < 2) {
    return;
  }

  dispatch({
    type: calculation_constants.DISABLE_PROPELLER,
    payload: position,
  });
  dispatch(
    calculateChange({
      belongs_to: "special_fields",
      name: "disable_propeller",
      position: 0,
      value: 0,
    })
  );
};

export const enablePropeller = (position) => async (dispatch) => {
  dispatch({
    type: calculation_constants.ENABLE_PROPELLER,
    payload: position,
  });

  dispatch(
    calculateChange({
      belongs_to: "special_fields",
      name: "enable_propeller",
      position: 0,
      value: 0,
    })
  );
};

export const duplicatePropeller = (position) => async (dispatch, getState) => {
  const {
    calculation: { table_propeller },
  } = getState();
  // We can duplicate only if the propeller is enabled
  if (!table_propeller[position].is_enabled) {
    return;
  }
  if (table_propeller.length < 5) {
    dispatch({
      type: calculation_constants.DUPLICATE_PROPELLER,
      payload: position,
    });
    dispatch(
      calculateChange({
        belongs_to: "special_fields",
        name: "add_propeller",
        position: 0,
        value: 0,
      })
    );
  }
};

export const changeContainerType = (value) => async (dispatch) => {
  dispatch({
    type: calculation_constants.CHANGE_CONTAINER_TYPE,
    payload: value,
  });

  dispatch(
    calculateChange({
      belongs_to: "table_container_type",
      name: "container_type",
      position: 0,
      value,
    })
  );
};

export const calculateChange =
  ({ name, belongs_to, position = 0, value }) =>
  async (dispatch, getState) => {
    const {
      calculation,
      user_sign_in: { user_info },
    } = getState();

    if (!user_info) {
      return;
    }
    const { error } = calculation;

    const calculation_data = makeCalculationRequest(calculation);
    const body = {
      change_source: {
        belongs_to,
        field_name: name,
        value,
        position: parseInt(position),
      },
      calculation_data,
    };

    dispatch({
      type: calculation_constants.CALCULATE_CHANGE_REQUEST,
    });
    const { data, success, status } = await calculateChangeService(
      body,
      user_info.access
    );

    if (success) {
      dispatch({
        type: calculation_constants.CALCULATE_CHANGE_REQUEST_SUCCESS,
        payload: data,
      });
    } else {
      if (status === false && status !== 401 && status !== 403) {
        error.show_error = true;
      } else if (
        status === 400 &&
        !(data.data.source_element in error.errors_in)
      ) {
        // pusheamos el error en el array de errores
        error.errors_in[data.data.source_element] = {
          name: data.data.source_element,
          belongs_to: data.data.belongs_to,
          position: data.data.position,
        };
        error.show_error = true;
      }
      error.message = data.message;
      dispatch({
        type: calculation_constants.CALCULATE_CHANGE_REQUEST_FAIL,
        payload: { ...error },
      });
      dispatch({
        type: calculation_constants.SET_MESSAGE,
        payload: data.message ? data.message : "Something went wrong",
      });
    }
  };

export const onMobileTypeChange =
  ({ position = 0, value }) =>
  async (dispatch, getState) => {
    const { calculation } = getState();
    let last_mobile_type =
      calculation.table_propeller[position].data.mobile_type.value;
    const mobile_type_new_state = getGroupOfMobileTypeChange({
      new_value: value,
      last_value: last_mobile_type,
    });

    // Solamente cuando sean de distintos grupos el nuevo y el anterior
    // Avisamos que hubo cambio, para estar evitando renderizados
    // innecesarios
    // si no pues solo mandamos la llamada al servidor para recalcular
    if (mobile_type_new_state) {
      dispatch({
        type: calculation_constants.ON_MOBILE_TYPE_CHANGE,
        payload: {
          ...mobile_type_new_state,
          position,
          new_value: value,
        },
      });
    }
    dispatch(
      calculateChange({
        belongs_to: "table_propeller",
        name: "mobile_type",
        position: parseInt(position),
        value,
      })
    );

    // Aquí debe ir la llamada a la api para avisar que se hizo un cambio y que
    // vuelva a recalcular si es necesario
  };

export const onAxisDiameterAxisThicknessAxisTypeChange =
  ({ name, value }) =>
  async (dispatch, getState) => {
    // dispatch({
    //   type: CALCULATION.ON_AD_ATH_AT_CHANGE,
    //   payload: {
    //     name,
    //     value,
    //   },
    // });

    dispatch(
      calculateChange({
        belongs_to: "table_axis",
        name,
        value,
      })
    );
  };

export const getCalculationById =
  ({ id }) =>
  async (dispatch, getState) => {
    const {
      user_sign_in: { user_info },
    } = getState();

    if (!user_info) {
      return;
    }
    dispatch({ type: calculation_constants.CALCULATION_BY_ID_REQUEST });

    const { data, success } = await getCalculationByIdService(
      id,
      user_info.access
    );
    const state = transformCalculationResponse(data);

    if (success) {
      dispatch({
        type: calculation_constants.CALCULATION_BY_ID_REQUEST_SUCCESS,
        payload: { ...state },
      });

      for (let index in state.table_propeller) {
        let mobile_type_new_state = getGroupOfMobileTypeChange({
          new_value: state.table_propeller[index].data.mobile_type.value,
          last_value: "Ninguno",
        });

        dispatch({
          type: calculation_constants.ON_MOBILE_TYPE_CHANGE,
          payload: {
            ...mobile_type_new_state,
            position: index,
            new_value: state.table_propeller[index].data.mobile_type.value,
          },
        });
      }
    } else {
      dispatch({ type: calculation_constants.CALCULATION_BY_ID_REQUEST_FAIL });
    }
  };

export const saveCalculation =
  (download = false) =>
  async (dispatch, getState) => {
    const {
      calculation,
      user_sign_in: { user_info },
    } = getState();

    if (!user_info) {
      return;
    }

    const { error } = calculation;

    if (Object.entries(error.errors_in).length > 0) {
      dispatch({
        type: calculation_constants.SET_MESSAGE,
        payload: "Can't save. You have errors to solve",
      });
      return;
    }
    let request = makeCalculationRequest(calculation, [
      "table_gear_units",
      "loading",
      "error",
      "create",
      "edit",
      "message",
      "history",
    ]);

    if (calculation.edit.is_edit) {
      dispatch({ type: calculation_constants.CALCULATION_UPDATE_REQUEST });
      let { data, success } = await updateCalculationService(
        calculation.edit.data.id_calculation,
        request,
        user_info.access
      );

      if (success) {
        // We download if the user click and save and download pdf
        if (download) {
          dispatch(getPDFDownload(calculation.edit.data.id_calculation));
        }
        dispatch({
          type: calculation_constants.CALCULATION_UPDATE_REQUEST_SUCCESS,
        });
        dispatch(
          getCalculationById({ id: calculation.edit.data.id_calculation })
        );
      } else {
        dispatch({
          type: calculation_constants.CALCULATION_UPDATE_REQUEST_FAIL,
        });
        dispatch({
          type: calculation_constants.SET_MESSAGE,
          payload: data.message
            ? data.message
            : data.detail
            ? data.detail
            : "Something went wrong",
        });
      }
    } else {
      dispatch({ type: calculation_constants.CALCULATION_CREATE_REQUEST });
      const { data, success } = await createCalculationService(
        request,
        user_info.access
      );
      if (success) {
        // We download if the user click and save and download pdf
        if (download) {
          dispatch(getPDFDownload(data.id));
        }
        dispatch({
          type: calculation_constants.CALCULATION_CREATE_REQUEST_SUCCESS,
        });
        dispatch({
          type: calculation_constants.RESET_CALCULATION,
        });
      } else {
        dispatch({
          type: calculation_constants.CALCULATION_CREATE_REQUEST_FAIL,
        });
        dispatch({
          type: calculation_constants.SET_MESSAGE,
          payload: data.message
            ? data.message
            : data.detail
            ? data.detail
            : "Something went wrong",
        });
      }
    }
  };

export const getCalculationLogs =
  ({ id, page = null }) =>
  async (dispatch, getState) => {
    const {
      user_sign_in: { user_info },
    } = getState();
    if (!user_info) {
      return;
    }
    let query = "";
    if (page !== null) {
      query += `?page=${page}`;
    }

    dispatch({
      type: calculation_constants.CALCULATION_CHANGE_LOG_REQUEST,
    });
    const { data, success } = await getCalculationLogsService(
      user_info.access,
      id,
      query
    );

    if (success) {
      dispatch({
        type: calculation_constants.CALCULATION_CHANGE_LOG_REQUEST_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: calculation_constants.CALCULATION_CHANGE_LOG_REQUEST_FAIL,
        payload: data.message
          ? data.message
          : data.detail
          ? data.detail
          : "Something went wrong",
      });
    }
  };
