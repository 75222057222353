import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";
import CalcInput from "@/components/CalcInput";
import CalcSelect from "@/components/CalcSelect";
import { getContainerTypeContent } from "@/helpers/tables_content/container_type_content";
import useChangeTable from "@/hooks/useChangeTable";
import { useSelector, useDispatch } from "react-redux";
import {
  calculateChange,
  changeContainerType,
} from "@/actions/calculation.actions";
import getClassDependsOn, { outputOnly } from "@/helpers/getClassDependsOn";
function TableContainerType() {
  const ref = React.useRef();
  const dispatch = useDispatch();

  const calculation = useSelector((state) => state.calculation);
  const { table_container_type, error } = calculation;

  const handleChange = (e) => {
    if (e.target.name === "container_type") {
      dispatch(changeContainerType(e.target.value));
    } else {
      dispatch(
        calculateChange({
          belongs_to: "table_container_type",
          name: e.target.name,
          value: e.target.value,
        })
      );
    }
  };

  useChangeTable({ ref, handleChange });

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="table work conditions">
        <TableBody ref={ref}>
          <TableRow>
            <TableCell padding="none" sx={{ padding: "0 10px" }}>
              Tipo de contenedor
            </TableCell>
            <TableCell padding="none" align="right">
              <CalcSelect
                name="container_type"
                state_value={
                  table_container_type.container_type.value
                    ? table_container_type.container_type.value
                    : ""
                }
              >
                <option value="Cilíndrico">Cilíndrico</option>
                <option value="Cuadrangular">Cuadrangular</option>
              </CalcSelect>
            </TableCell>
          </TableRow>

          {getContainerTypeContent(
            table_container_type.container_type.value
          ).map((item, index) => {
            let row = null;
            if (item.is_visible) {
              row = (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell padding="none" sx={{ padding: "0 10px" }}>
                      {item.label}
                    </TableCell>
                    <TableCell padding="none" align="right">
                      <CalcInput
                        sx={getClassDependsOn(
                          error.errors_in,
                          item.name,
                          true,
                          item.class
                        )}
                        readOnly={item.read_only}
                        disabled={item.read_only}
                        name={item.name}
                        state_value={
                          table_container_type[item.name].rounded_value
                            ? table_container_type[item.name].rounded_value
                            : table_container_type[item.name].value
                            ? table_container_type[item.name].value
                            : ""
                        }
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            }

            return row;
          })}
          <TableRow>
            <TableCell padding="none" sx={{ padding: "0 10px" }}>
              Vol. útil m<sup>3</sup>
            </TableCell>
            <TableCell padding="none" align="right">
              <CalcInput
                readOnly={true}
                disabled={true}
                sx={outputOnly}
                name="container_useful_volume"
                state_value={
                  table_container_type.container_useful_volume.rounded_value
                    ? table_container_type.container_useful_volume.rounded_value
                    : table_container_type.container_useful_volume.value
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableContainerType;
