import React from "react";
import MuiAutocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import CalcInput from "@/components/CalcInput";
import { Box } from "@mui/material";
const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    fontSize: ".8em",
    padding: 0,
    margin: 0,
    fontFamily: [
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
  },
  option: {
    height: "auto",
  },
});

function CalculationAutocomplete(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(
    props.state_value ? props.state_value : ""
  );

  React.useEffect(() => {
    setValue(props.state_value);
  }, [props.state_value]);
  return (
    <MuiAutocomplete
      disabled={props.disabled}
      id={props.name}
      classes={classes}
      freeSolo
      disableClearable
      inputValue={value}
      onChange={(e, new_value) => {
        setValue(new_value);
        let table_body = document.getElementById(props.table_body_id);
        const evt = new Event("change", { bubbles: true });
        evt.data = {
          name: props.name,
          value: new_value,
          indexNumber: props.index,
        };
        table_body.dispatchEvent(evt);
      }}
      onInputChange={(e, new_value) => {
        setValue(new_value);
      }}
      options={props.options.map((option) => option)}
      renderInput={(params) => (
        <Box ref={params.InputProps.ref}>
          <CalcInput
            state_value={value}
            name={props.name}
            componentsProps={{
              input: {
                "data-index-number": props.index,
              },
            }}
            fulWidth={true}
            {...params.inputProps}
          />
        </Box>
      )}
    />
  );
}

export default CalculationAutocomplete;
