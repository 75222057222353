export const initialStateContainerType = (
  container_type = "Contenedor cilíndrico"
) => {
  let is_visible = container_type !== "Contenedor cilíndrico";
  return {
    container_type: {
      is_visible: true,
      name: "container_type",
      value: "Cilíndrico",
      classes: "",
    },
    container_width: {
      is_visible: is_visible,
      name: "container_width",
      value: "",
      classes: "",
    },
    container_length: {
      is_visible: is_visible,
      name: "container_length",
      value: "",
      classes: "",
    },
    container_diameter: {
      is_visible: true,
      name: "container_diameter",
      value: "",
      classes: "",
    },
    container_height: {
      is_visible: true,
      name: "container_height",
      value: "",
      classes: "",
    },
    container_useful_volume: {
      is_visible: true,
      name: "container_useful_volume",
      value: "",
      classes: "",
    },
  };
};
