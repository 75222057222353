import { user_constants } from "../constants/user.constants";

export const userSignInReducer = (
  state = { loading: false, error: null, show_account_not_activated: false },
  action
) => {
  switch (action.type) {
    case user_constants.SIGNIN_REQUEST:
      return { loading: true };

    case user_constants.SIGNIN_REQUEST_SUCCESS:
      return {
        loading: false,
        user_info: action.payload,
        show_account_not_activated:
          action.payload.is_activated === true ? false : true,
      };

    case user_constants.SIGNIN_REQUEST_FAIL:
      return { loading: false, error: action.payload };

    case user_constants.REFRESH_REQUEST:
      return { ...state, loading: true };
    case user_constants.REFRESH_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        user_info: {
          ...state.user_info,
          access: action.payload.access,
        },
      };
    case user_constants.REFRESH_REQUEST_FAIL:
      return { loading: false };

    case user_constants.PASSWORD_RESET_REQUEST:
      return { ...state, loading: true };
    case user_constants.PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case user_constants.PASSWORD_RESET_REQUEST_FAIL:
      return { ...state, loading: false };

    case user_constants.REMOVE_ERROR_MESSAGE: {
      return { ...state, error: null };
    }

    case user_constants.SHOW_ACCOUNT_NOT_ACTIVATED: {
      return { ...state, show_account_not_activated: action.payload };
    }

    default:
      return state;
  }
};
