import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useIsAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_sign_in = useSelector((state) => state.user_sign_in);
  const { user_info } = user_sign_in;
  useEffect(() => {
    if (!user_info) {
      navigate("/signin");
    }
  }, [navigate, user_info, location]);
};

export default useIsAuth;
