import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainerMui from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color:
    //   theme.palette.mode === "dark"
    //     ? theme.palette.text.primary
    //     : theme.palette.common.white,
    fontSize: 13,
    fontWeight: 600,
    padding: "5px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
  borderBottom:
    theme.palette.mode === "dark" ? "1px solid #333333" : "1px solid #f5f5f5",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
  // "&:hover": {
  //   backgroundColor: theme.palette.action.disabled,
  // },
}));

const TableContainer = (props) => {
  return (
    <TableContainerMui component={Paper} {...props}>
      {props.children}
    </TableContainerMui>
  );
};

export {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  StyledTableCell as TableCell,
  StyledTableRow as TableRow,
};
