import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import formatDate from "@/helpers/formatDate";

const ReportsFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState([null, null]);
  const reports = useSelector((state) => state.reports);
  const {
    reports_data: { users },
  } = reports;

  const submitHandler = (e) => {
    e.preventDefault();
    let query = `/reports?`;
    const query_params = new URLSearchParams(location.search);
    for (let i = 0; i < 5; i++) {
      let field = e.target.elements[i];
      if (i % 2 != 0) {
        field.name = i == 1 ? "start_date" : "end_date";
        field.value =
          i == 1
            ? value[0]
              ? formatDate({ date: value[0] })
              : // ? formatDate({ date: formatDate(value[0]), options: {} })
                ""
            : value[1]
            ? formatDate({ date: value[1] })
            : // ? formatDate({ date: formatDate(value[1]), options: {} })
              "";
      }

      if (field.value != "") {
        if (query_params.has(field.name)) {
          query_params.set(field.name, field.value);
        } else {
          query_params.append(field.name, field.value);
        }
      } else {
        if (query_params.has(field.name)) {
          query_params.delete(field.name);
        }
      }
    }
    if (query_params.has("page")) {
      query_params.delete("page");
    }

    query += query_params.toString();
    navigate(query);
  };

  return (
    <Box
      component="form"
      onSubmit={submitHandler}
      sx={{ width: "100%" }}
      autoComplete="off"
    >
      <Paper
        sx={{
          width: "100%",
          padding: "9px",
          marginBottom: "27px",
          boxShadow: "rgb(106, 113, 153) 0px 0px 2px",
        }}
      >
        <Grid sx={{ padding: "10px" }} container spacing={3}>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-search-id">Id</InputLabel>
              <OutlinedInput
                fullWidth={true}
                label="Id"
                name="id_calculation"
                id="outlined-adornment-search-id"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-user">Usuario</InputLabel>
              <Select
                name="user"
                input={
                  <OutlinedInput
                    fullWidth={true}
                    label="Usuario"
                    id="outlined-adornment-user"
                  />
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {users.map((item) => {
                  return (
                    <MenuItem key={item.id_user} value={item.id_user}>
                      {item.username}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-search-id">
                Modelo
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="detail_model"
                label="Modelo"
                id="outlined-adornment-search-model"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateRangePicker
                startText="Inicio"
                endText="Final"
                // views={["year", "month", "day"]}
                // format="DD-MM-YYYY"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField fullWidth {...startProps} name="start_date" />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField fullWidth {...endProps} name="end_date" />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button type="submit" variant="contained">
            Buscar
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ReportsFilter;
