import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@/components/Table";
import GearUnits from "./GearUnits";
import {
  coaxial_nord,
  coaxial_sew,
  parallel_sew,
  parallel_nord,
} from "@/helpers/tables_content/gear_units_content";
import { useSelector } from "react-redux";

function TableGearUnits() {
  const calculation = useSelector((state) => state.calculation);
  const {
    table_axis: {
      total_axis_length,
      total_installed_power,
      equivalent_radial_force,
    },
    table_work_conditions: { n_rpm },
  } = calculation;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          padding: "0 5%",
          paddingBottom: "5%",
          display: "flex",
          width: "100%",
          maxWidth: 1500,
        }}
      >
        <Grid container spacing={1.5}>
          <Grid item sm={2}>
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                aria-label="table vertical gear units"
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      Selección reductor vertical
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      padding="none"
                      sx={(theme) => ({
                        padding: "0 10px",
                        height: "25px",
                        borderRight:
                          theme.palette.mode === "dark"
                            ? "1px solid #333333"
                            : "1px solid #f5f5f5",
                      })}
                    >
                      <span>Esfuerzo radial daN</span>
                    </TableCell>
                    <TableCell
                      padding="none"
                      sx={{ minWidth: 50 }}
                      align="center"
                    >
                      <span>{equivalent_radial_force.rounded_value}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      padding="none"
                      sx={(theme) => ({
                        padding: "0 10px",
                        height: "25px",
                        borderRight:
                          theme.palette.mode === "dark"
                            ? "1px solid #333333"
                            : "1px solid #f5f5f5",
                      })}
                    >
                      <span>Longitud eje m</span>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <span>{total_axis_length.value}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      padding="none"
                      sx={(theme) => ({
                        padding: "0 10px",
                        height: "25px",
                        borderRight:
                          theme.palette.mode === "dark"
                            ? "1px solid #333333"
                            : "1px solid #f5f5f5",
                      })}
                    >
                      <span>C Nmm</span>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <span></span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      padding="none"
                      sx={(theme) => ({
                        padding: "0 10px",
                        height: "25px",
                        borderRight:
                          theme.palette.mode === "dark"
                            ? "1px solid #333333"
                            : "1px solid #f5f5f5",
                      })}
                    >
                      <span>Pi kw</span>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <span>{total_installed_power.value}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      padding="none"
                      sx={(theme) => ({
                        padding: "0 10px",
                        height: "25px",
                        borderRight:
                          theme.palette.mode === "dark"
                            ? "1px solid #333333"
                            : "1px solid #f5f5f5",
                      })}
                    >
                      <span>N rpm</span>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <span>{n_rpm.value}</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item container sm={10} spacing={1.5}>
            <Grid item sm={6}>
              <GearUnits content={coaxial_sew} />
            </Grid>
            <Grid item sm={6}>
              <GearUnits content={coaxial_nord} />
            </Grid>
            <Grid item sm={6}>
              <GearUnits content={parallel_sew} />
            </Grid>
            <Grid item sm={6}>
              <GearUnits content={parallel_nord} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TableGearUnits;
