import { combineReducers } from "redux";
import { calculationReducer } from "./calculation.reducer";
import { reportsReducer } from "./reports.reducer";
import { userSignInReducer } from "./user.reducer";
import { user_constants } from "../constants/user.constants";

const appReducer = combineReducers({
  calculation: calculationReducer,
  reports: reportsReducer,
  user_sign_in: userSignInReducer,
});

const rootReducer = (state, action) => {
  if (action.type === user_constants.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
