export const initialStateWorkConditions = () => {
  return {
    n_rpm: {
      name: "n_rpm",
      value: "",
      error: false,
      message_error: "",
    },
    density_kg_m_3: {
      name: "density_kg_m_3",
      value: 1000,
      error: false,
      message_error: "",
    },
    viscosity_cps: {
      name: "viscosity_cps",
      value: 1,
      error: false,
      message_error: "",
    },
  };
};
