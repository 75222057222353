export const getMixerFeaturesContent = () => {
  return [
    {
      label: "Material",
      name: "mixer_material",
    },
    {
      label: "Tipo de agit.",
      name: "mixer_type",
    },
    {
      label: "Ángulo",
      name: "angle",
    },
  ];
};
