import React from "react";
import Layout from "@/components/Layout";
import { useNavigate } from "react-router-dom";
import useIsAuth from "@/hooks/useIsAuth";
import { useDispatch, useSelector } from "react-redux";
// import { user_constants } from "@/constants/user.constants";
import { passwordReset } from "@/actions/user.actions";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
// import { Snackbar } from "@mui/material";
// import { Alert } from "@mui/material";

const EmailTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.text.brand,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.text.brand,
  },
  "& input:invalid": {
    borderColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: theme.palette.text.brand,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.text.brand,
    },
  },
}));

function PasswordResetPage() {
  useIsAuth();
  const [emailSend, setEmailSend] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_sign_in } = useSelector((state) => state);
  const { loading } = user_sign_in;

  const submitHandler = async (e) => {
    e.preventDefault();
    let success = await dispatch(passwordReset(e.target.elements[0].value));

    if (success === true) {
      setEmailSend(true);
    }
  };

  return (
    <Layout>
      {/* <Snackbar
        open={error ? true : false}
        onClose={() => {
          dispatch({ type: user_constants.REMOVE_ERROR_MESSAGE });
        }}
      >
        <Alert elevation={6} variant="filled" severity="error">
          Algo salió mal
        </Alert>
      </Snackbar> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 1200,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "flex", width: "100%", padding: "10px 0" }}>
            <IconButton
              size="medium"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack fontSize="medium"></ArrowBack>
            </IconButton>
          </Box>
          {loading ? (
            <Box>
              <CircularProgress></CircularProgress>
            </Box>
          ) : (
            <>
              {emailSend ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography mb={1.5} variant="h5">
                    Correo enviado
                  </Typography>
                  <Typography>
                    Le hemos enviado instrucciones por correo electrónico para
                    establecer su contraseña.
                  </Typography>
                  <Typography>
                    Si existe una cuenta con el correo electrónico que ingresó,
                    debería recibirlo en breve.
                  </Typography>
                  <Typography>
                    Si no recibe un correo electrónico, asegúrese de haber
                    ingresado la dirección con la que se registró.
                  </Typography>
                  <Typography>
                    Una vez reestablecida la contraseña,{" "}
                    <strong>inicie sesión nuevamente</strong> para poder
                    continuar.
                  </Typography>
                </Box>
              ) : (
                <Box
                  component="form"
                  onSubmit={submitHandler}
                  autoComplete="off"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5">Solicitar contraseña</Typography>
                  <Typography mb={1.5} variant="body1">
                    Ingrese su dirección de correo electrónico y le enviaremos
                    instrucciones por correo electrónico para configurar una
                    nueva.
                  </Typography>
                  <EmailTextField
                    size="small"
                    label="Email"
                    type="email"
                    required
                  />
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.text.primary,
                        "&:hover": {
                          backgroundColor: theme.palette.text.brand,
                        },
                      })}
                    >
                      Enviar
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export default PasswordResetPage;
