import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";
import CalcSelect from "@/components/CalcSelect";
import CalcInput from "@/components/CalcInput";
import useChangeTable from "@/hooks/useChangeTable";
import { useDispatch, useSelector } from "react-redux";
import { calculateChange } from "@/actions/calculation.actions";
import getClassDependsOn from "@/helpers/getClassDependsOn";

function TableMixerFeatures() {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.calculation);
  const { table_mixer_features, error } = calculation;
  const handleChange = (e) => {
    dispatch(
      calculateChange({
        belongs_to: "table_mixer_features",
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  useChangeTable({
    ref,
    handleChange,
  });

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="table mixer features">
        <TableBody ref={ref}>
          <TableRow>
            <TableCell padding="none" sx={{ padding: "0 10px" }}>
              Material
            </TableCell>
            <TableCell padding="none" align="right">
              <CalcSelect
                name="mixer_material"
                state_value={table_mixer_features.mixer_material.value}
              >
                {/* <option aria-label="None" value="" /> */}
                <option value="Acero carbono">Acero carbono</option>
                <option value="Acero inox. 304, 316">
                  Acero inox. 304, 316
                </option>
                <option value="Acero inox. 904L">Acero inox. 904L</option>
                <option value="Duplex">Duplex</option>
                <option value="Super Duplex">Super Duplex</option>
                <option value="Titanio">Titanio</option>
                <option value="Hastelloy">Hastelloy</option>
              </CalcSelect>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="none" sx={{ padding: "0 10px" }}>
              Tipo de agit.
            </TableCell>
            <TableCell padding="none" align="right">
              <CalcSelect
                name="mixer_type"
                state_value={table_mixer_features.mixer_type.value}
              >
                {/* <option aria-label="None" value="" /> */}
                <option value="Vertical centrado">Vertical centrado</option>
                <option value="Vert. descentrado normal">
                  Vert. descentrado normal
                </option>
                <option value="Vert. descentrado máximo">
                  Vert. descentrado máximo
                </option>
                <option value="Vertical con hce. en tubo">
                  Vertical con hce. en tubo
                </option>
                <option value="Vertical para dispersión">
                  Vertical para dispersión
                </option>
                <option value="Horizontal">Horizontal</option>
              </CalcSelect>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="none" sx={{ padding: "0 10px" }}>
              {table_mixer_features.mixer_type.value == "Horizontal" ? (
                <>Angulo horizontal °</>
              ) : (
                <>Angulo vertical °</>
              )}
            </TableCell>
            <TableCell padding="none" align="right">
              <CalcInput
                sx={getClassDependsOn(error.errors_in, "angle", true, {})}
                name="angle"
                state_value={table_mixer_features.angle.value}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableMixerFeatures;
