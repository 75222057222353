import { calculation_constants } from "../constants/calculation.constants";
import {
  initialStatePropeller,
  initialStateWorkConditions,
  initialStateContainerType,
  initialStateAxis,
  initialStateBottomSweep,
  initialStateMixerFeatures,
  initialStateGearUnits,
  initialStateBottomBearing,
} from "../helpers/calculation_initial_state";
let id_ai = 0;

export const calculationInitialState = () => {
  return {
    table_work_conditions: initialStateWorkConditions(),
    table_mixer_features: initialStateMixerFeatures(),
    table_container_type: initialStateContainerType(),
    table_propeller: [
      {
        id: id_ai,
        position: 0,
        is_enabled: true,
        data: initialStatePropeller(),
      },
    ],
    table_axis: initialStateAxis(),
    table_detail: {
      detail_total_cost: "",
      detail_model: "",
      details: [],
    },
    table_bottom_sweep: initialStateBottomSweep(),
    table_comments: "",
    table_gear_units: initialStateGearUnits(),
    table_bottom_bearing: initialStateBottomBearing(),
    create: {
      loading: false,
      error: false,
    },
    edit: {
      is_edit: false,
      loading: false,
      error: false,
      data: {},
    },
    history: {
      initial_load: false,
      loading: false,
      error: false,
      results: [],
      pagination: {
        current_page: 0,
        count: 0,
        total_pages: 0,
      },
    },
    message: {
      show: false,
      info: {},
    },
  };
};

const error = {
  show_error: false,
  message: "",
  errors_in: {},
};
export function calculationReducer(
  state = {
    ...calculationInitialState(),
    loading: false,
    error: { ...error },
  },
  action
) {
  let new_state = state;
  switch (action.type) {
    case calculation_constants.HIDE_CALCULATION_ERROR:
      return { ...state, error: { ...state.error, show_error: false } };

    case calculation_constants.RESET_CALCULATION:
      return {
        ...calculationInitialState(),
        loading: false,
        error: { show_error: false, message: "", errors_in: {} },
      };

    case calculation_constants.ADD_PROPELLER:
      new_state = {
        ...state,
        table_propeller: [
          ...state.table_propeller,
          {
            id: ++id_ai,
            position: state.table_propeller.length,
            is_enabled: true,
            data: initialStatePropeller(),
          },
        ],
      };
      break;

    case calculation_constants.DELETE_PROPELLER:
      let cont = 0;
      let new_table = state.table_propeller.filter((item) => {
        if (item.id !== action.payload) {
          item.position = cont;
          cont++;
          return item;
        }
        return null;
      });
      new_state = {
        ...state,
        table_propeller: new_table,
      };

      break;

    case calculation_constants.DISABLE_PROPELLER:
      return {
        ...state,
        table_propeller: [
          ...state.table_propeller.map((item) => {
            if (item.position === action.payload) {
              item.is_enabled = false;
            }
            return item;
          }),
        ],
      };

    case calculation_constants.ENABLE_PROPELLER:
      return {
        ...state,
        table_propeller: [
          ...state.table_propeller.map((item) => {
            if (item.position === action.payload) {
              item.is_enabled = true;
            }
            return item;
          }),
        ],
      };

    case calculation_constants.DUPLICATE_PROPELLER:
      // Find the propeller to duplicate
      let duplicated = state.table_propeller.find(
        (_, i) => i == action.payload
      ).data;
      let new_dup = {};
      // If we don't do this, the references are the same for the original and the duplicate propeller.
      Object.keys(initialStatePropeller()).forEach((key) => {
        new_dup[key] = { ...duplicated[key] };
      });
      new_state = {
        ...state,
        table_propeller: [
          ...state.table_propeller,
          {
            id: ++id_ai,
            position: state.table_propeller.length,
            is_enabled: true,
            data: new_dup,
          },
        ],
      };
      break;

    case calculation_constants.CHANGE_CONTAINER_TYPE:
      // state.table_container_type.container_width.value = "";
      // state.table_container_type.container_length.value = "";
      // state.table_container_type.container_diameter.value = "";
      let init_table_container_type_aux = initialStateContainerType();
      return {
        ...state,
        table_container_type: {
          ...state.table_container_type,
          container_width: { ...init_table_container_type_aux.container_width },
          container_length: {
            ...init_table_container_type_aux.container_length,
          },
          container_diameter: {
            ...init_table_container_type_aux.container_diameter,
          },
        },
      };

    case calculation_constants.CALCULATE_CHANGE_REQUEST:
      return { ...state, loading: true };

    case calculation_constants.CALCULATE_CHANGE_REQUEST_SUCCESS:
      const data = action.payload.calculated_data;
      for (let key in data) {
        let item = data[key];
        let table = new_state[item.belongs_to];
        try {
          // Esto es distinto porque es un array de hélices y hay que identificar
          // en que hélice se hizo el cambio.
          if (item.belongs_to === "table_propeller") {
            for (let pos_key in item.data) {
              let propeller = table[item.data[pos_key].position];
              propeller.data[item.field_name].value = item.data[pos_key].value;
              propeller.data[item.field_name].rounded_value =
                item.data[pos_key].rounded_value;
              table[item.data[pos_key].position] = propeller;

              if (item.field_name === "absorbed_power_calc") {
                propeller.data[item.field_name].equals_on =
                  item.data[pos_key].equals_on;
                propeller.data[item.field_name].multiplier =
                  item.data[pos_key].multiplier;
              }
              delete propeller.data[item.field_name].message;
              if ("message" in item.data[pos_key]) {
                propeller.data[item.field_name].message =
                  item.data[pos_key].message;
              }
            }
          } else {
            table[item.field_name].value = item.value;
            table[item.field_name].rounded_value = item.rounded_value;
            delete table[item.field_name].message;
            if ("message" in item) {
              table[item.field_name].message = item.message;
            }
          }

          new_state[item.belongs_to] = table;
        } catch (error) {
          // console.log(error);
        }
      }
      delete new_state.error.errors_in[action.payload.source_element];
      const aux_state = {
        ...new_state,
        loading: false,
      };
      return {
        ...aux_state,
      };

    case calculation_constants.CALCULATE_CHANGE_REQUEST_FAIL:
      return { ...state, loading: false, error: { ...action.payload } };

    case calculation_constants.ON_MOBILE_TYPE_CHANGE:
      let propeller = state.table_propeller[action.payload.position];
      propeller.data["mobile_type"] = {
        ...propeller.data["mobile_type"],
        value: action.payload.new_value,
      };

      if (action.payload.new_value === "Ninguno") {
        propeller.data = initialStatePropeller();
      } else {
        action.payload.items_disabled.forEach((element) => {
          propeller.data[element].is_enabled = false;
          propeller.data[element].value = "";
        });

        action.payload.items_enabled.forEach((element) => {
          propeller.data[element].is_enabled = true;
        });
      }

      return {
        ...state,
        table_propeller: state.table_propeller.map((x) => {
          if (x.position === action.payload.position) {
            x = propeller;
          }
          return x;
        }),
      };

    case calculation_constants.SET_CALCULATION_COMMENTS:
      return { ...state, table_comments: action.payload };

    case calculation_constants.SET_DETAIL_PROP:
      state.table_detail[action.payload.name] = action.payload.value;
      return { ...state, table_detail: { ...state.table_detail } };
    case calculation_constants.SET_DETAIL:
      let row = state.table_detail.details.find(
        (x) => x.detail_position == action.payload.detail_position
      );
      if (row) {
        row[action.payload.name] = action.payload.value;
        if (
          row.detail_name == "" &&
          row.detail == "" &&
          row.detail_price == ""
        ) {
          state.table_detail.details = state.table_detail.details.filter(
            (item) => item.detail_position != action.payload.detail_position
          );
        }
      } else {
        let aux = {
          detail_name: "",
          detail: "",
          detail_price: "",
          detail_position: "",
        };
        aux[action.payload.name] = action.payload.value;
        aux["detail_position"] = action.payload.detail_position;
        state.table_detail.details.push(aux);
        state.table_detail.details.sort((a, b) => {
          return a.detail_position - b.detail_position;
        });
      }

      return { ...state, table_detail: { ...state.table_detail } };

    // Edit on
    case calculation_constants.CALCULATION_BY_ID_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          is_edit: true,
          loading: true,
        },
      };
    case calculation_constants.CALCULATION_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        edit: {
          ...state.edit,
          loading: false,
          data: { ...action.payload.edit.data },
        },
      };
    case calculation_constants.CALCULATION_BY_ID_REQUEST_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          error: true,
        },
      };

    case calculation_constants.CALCULATION_CREATE_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
        },
      };
    case calculation_constants.CALCULATION_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: false,
        },
      };
    case calculation_constants.CALCULATION_CREATE_REQUEST_FAIL:
      return {
        ...state,
        create: {
          loading: false,
          error: true,
        },
      };

    case calculation_constants.CALCULATION_UPDATE_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: true,
        },
      };
    case calculation_constants.CALCULATION_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          error: false,
        },
      };
    case calculation_constants.CALCULATION_UPDATE_REQUEST_FAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          error: true,
        },
      };

    case calculation_constants.SET_MESSAGE:
      return {
        ...state,
        message: {
          show: true,
          info: {
            message: action.payload,
          },
        },
      };
    case calculation_constants.HIDE_MESSAGE:
      return {
        ...state,
        message: {
          show: false,
          info: {},
        },
      };

    case calculation_constants.CALCULATION_CHANGE_LOG_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          initial_load: true,
          loading: true,
        },
      };
    case calculation_constants.CALCULATION_CHANGE_LOG_REQUEST_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          results: [...state.history.results, ...action.payload.results],
          pagination: {
            current_page: action.payload.current_page,
            count: action.payload.count,
            page_size: action.payload.page_size,
            total_pages: action.payload.total_pages,
          },
        },
      };
    case calculation_constants.CALCULATION_CHANGE_LOG_REQUEST_FAIL:
      return {
        ...state,
        history: {
          initial_load: false,
          loading: false,
          error: true,
          results: [],
          pagination: {
            current_page: 0,
            count: 0,
            total_pages: 0,
          },
        },
      };

    default:
      break;
  }

  return new_state;
}

export default calculationReducer;
