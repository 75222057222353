import { user_constants } from "../constants/user.constants.js";
import {
  getTokensService,
  refreshTokensService,
  passwordResetService,
} from "../services/auth.service";
export const signIn = (email, password) => async (dispatch) => {
  dispatch({
    type: user_constants.SIGNIN_REQUEST,
  });
  const { data, success, status } = await getTokensService(email, password);
  if (success) {
    dispatch({
      type: user_constants.SIGNIN_REQUEST_SUCCESS,
      payload: data,
    });
  } else {
    let message = "Algo salió mal";
    if (status === 401 || status === 400) {
      message = "Email o contraseña inválidas";
    }
    dispatch({
      type: user_constants.SIGNIN_REQUEST_FAIL,
      payload: message,
    });
  }
};

export const refreshToken = () => async (dispatch, getState) => {
  dispatch({ type: user_constants.REFRESH_REQUEST });

  const { data, success, status } = await refreshTokensService();

  if (success) {
    dispatch({ type: user_constants.REFRESH_REQUEST_SUCCESS, payload: data });
  } else {
    dispatch({ type: user_constants.REFRESH_REQUEST_FAIL });
    dispatch({ type: user_constants.LOGOUT });
  }

  return { status };
};

export const logout = () => (dispatch) => {
  dispatch({ type: user_constants.LOGOUT });
};

export const passwordReset = (email) => async (dispatch, getState) => {
  const {
    user_sign_in: { user_info },
  } = getState();

  if (!user_info) {
    return;
  }

  dispatch({ type: user_constants.PASSWORD_RESET_REQUEST });
  let { success } = await passwordResetService(user_info.access, email);

  if (success) {
    dispatch({ type: user_constants.PASSWORD_RESET_REQUEST_SUCCESS });
    return true;
  } else {
    dispatch({ type: user_constants.PASSWORD_RESET_REQUEST_FAIL });
    return false;
  }
};
