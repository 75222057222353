export const user_constants = {
  SIGNIN_REQUEST: "USER_SIGNIN_REQUEST",
  SIGNIN_REQUEST_SUCCESS: "USER_SIGNIN_REQUEST_SUCCESS",
  SIGNIN_REQUEST_FAIL: "USER_SIGNIN_REQUEST_FAIL",

  REFRESH_REQUEST: "USER_REFRESH_REQUEST",
  REFRESH_REQUEST_SUCCESS: "USER_REFRESH_REQUEST_SUCCESS",
  REFRESH_REQUEST_FAIL: "USER_REFRESH_REQUEST_FAIL",

  PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_REQUEST_SUCCESS: "PASSWORD_RESET_REQUEST_SUCCESS",
  PASSWORD_RESET_REQUEST_FAIL: "PASSWORD_RESET_REQUEST_FAIL",

  REMOVE_ERROR_MESSAGE: "USER_REMOVE_ERROR_MESSAGE",

  SHOW_ACCOUNT_NOT_ACTIVATED: "USER_SHOW_ACCOUNT_NOT_ACTIVATED",

  LOGOUT: "USER_LOGOUT",
};
