export const coaxial_sew = {
  title: "Coaxial Sew",
  state: "coaxial_sew_feqn",
  columns_title: ["Eje dxl mm", "F, EQ N", "Red. R", "Bridas mm"],
  content: [
    {
      axis: "20x40",
      red: "7",
      flange: "80/140/160",
    },
    {
      axis: "20x40",
      red: "17",
      flange: "120/140/160",
    },
    {
      axis: "25x50",
      red: "27",
      flange: "120/140/160",
    },
    {
      axis: "25x50",
      red: "37",
      flange: "120/160/200",
    },
    {
      axis: "30x60",
      red: "47",
      flange: "140/160/200",
    },
    {
      axis: "35x70",
      red: "57",
      flange: "160/200/250",
    },
    {
      axis: "35x70",
      red: "67",
      flange: "200/250",
    },
    {
      axis: "40x80",
      red: "77",
      flange: "250/300",
    },
    {
      axis: "50x100",
      red: "87",
      flange: "300/350",
    },
    {
      axis: "60x120",
      red: "97",
      flange: "300/450",
    },
    {
      axis: "70x140",
      red: "107",
      flange: "300/450",
    },
    {
      axis: "",
      red: "",
      flange: "",
    },
    {
      axis: "90x170",
      red: "137",
      flange: "450/550",
    },
    {
      axis: "110x210",
      red: "147",
      flange: "450/550",
    },
    {
      axis: "120x210",
      red: "167",
      flange: "550/660",
    },
  ],
};

export const coaxial_nord = {
  title: "Coaxial Nord",
  state: "coaxial_nord_feqn",
  columns_title: ["Eje dxl mm", "F, EQ N", "Red.", "Bridas mm"],
  content: [
    {
      axis: "20x40",
      red: "0xEF",
      flange: "120/140/160",
    },
    {
      axis: "",
      red: "",
      flange: "",
    },
    {
      axis: "",
      red: "",
      flange: "",
    },
    {
      axis: "25x50",
      red: "1xEF",
      flange: "120/140/160",
    },
    {
      axis: "30x60",
      red: "2xEF",
      flange: "160/200",
    },
    {
      axis: "35x70",
      red: "4xEF",
      flange: "200/250",
    },
    {
      axis: "",
      red: "",
      flange: "",
    },
    {
      axis: "40x80",
      red: "3xEF",
      flange: "250/300",
    },
    {
      axis: "45x90",
      red: "4xEF",
      flange: "200/250",
    },
    {
      axis: "55x110",
      red: "5xEF",
      flange: "250/300",
    },
    {
      axis: "65x130",
      red: "6xEF",
      flange: "300",
    },
    {
      axis: "75x140",
      red: "7xEF",
      flange: "350",
    },
    {
      axis: "90x170",
      red: "8xEF",
      flange: "450",
    },
    {
      axis: "110x210",
      red: "9xEF",
      flange: "450",
    },
    {
      axis: "130x250",
      red: "10xEF",
      flange: "550",
    },
  ],
};

export const parallel_sew = {
  title: "Ejes paralelos Sew",
  state: "parallel_sew_feqn",
  columns_title: ["Eje dxl mm", "F, EQ N", "Red. FF", "Bridas mm"],
  content: [
    {
      axis: "25x104",
      red: "27",
      flange: "160",
    },
    {
      axis: "30x120",
      red: "37",
      flange: "160",
    },
    {
      axis: "35x150",
      red: "47",
      flange: "200",
    },
    {
      axis: "",
      red: "",
      flange: "",
    },
    {
      axis: "40x166",
      red: "57",
      flange: "250",
    },
    {
      axis: "40x180",
      red: "67",
      flange: "250",
    },
    {
      axis: "50x210",
      red: "77",
      flange: "300",
    },
    {
      axis: "60x240",
      red: "87",
      flange: "350",
    },
    {
      axis: "70x300",
      red: "97",
      flange: "450",
    },
    {
      axis: "90x350",
      red: "107",
      flange: "450",
    },
    {
      axis: "100x410",
      red: "127",
      flange: "550",
    },
    {
      axis: "120x500",
      red: "157",
      flange: "660",
    },
  ],
};

export const parallel_nord = {
  title: "Ejes paralelos Nord",
  state: "parallel_nord_feqn",
  columns_title: ["Eje dxl mm", "F, EQ N", "Red. GFL", "Bridas mm"],
  content: [
    {
      axis: "35x100",
      red: "0182 AF",
      flange: "140",
    },
    {
      axis: "25/30x122",
      red: "0282 AF",
      flange: "160",
    },
    {
      axis: "30x122",
      red: "1282 AF",
      flange: "200",
    },
    {
      axis: "35x176",
      red: "1382 AF",
      flange: "160/200",
    },
    {
      axis: "35x139",
      red: "2x82 AF",
      flange: "250",
    },
    {
      axis: "40x174",
      red: "3x82 AF",
      flange: "250/300",
    },
    {
      axis: "50x195",
      red: "4x82 AF",
      flange: "250/300",
    },
    {
      axis: "60x230",
      red: "5x82",
      flange: "350",
    },
    {
      axis: "70x290",
      red: "6x82",
      flange: "400/450",
    },
    {
      axis: "80x310",
      red: "7x82",
      flange: "450/550",
    },
    {
      axis: "100x366",
      red: "8x82",
      flange: "550",
    },
    {
      axis: "120x430",
      red: "9x82",
      flange: "660",
    },
  ],
};
