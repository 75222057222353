import axios_auth from "../middlewares/refresh.middleware";

export const calculateChangeService = async (body, access) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };

    const { data } = await axios_auth.post(
      `${process.env.REACT_APP_API_URL}/1.0/calculate/`,
      body,
      config
    );
    return { data: data, success: true };
  } catch (error) {
    let status = false;
    let message = "";
    let data = {};

    if (error.response) {
      status = error.response.status;
      message = error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
        ? error.response.data.detail
        : "Algo salió mal";
      data = error.response.data.data;
    } else if (error.status) {
      status = error.status;
    } else {
      message = "Algo salió mal";
    }
    return { data: { message, data: data }, success: false, status };
  }
};

export const getCalculationByIdService = async (id_calculation, access) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const { data } = await axios_auth.get(
      `${process.env.REACT_APP_API_URL}/1.0/calculations/${id_calculation}`,
      config
    );

    return { data, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};

export const createCalculationService = async (body, access) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };

    const { data } = await axios_auth.post(
      `${process.env.REACT_APP_API_URL}/1.0/calculation/`,
      body,
      config
    );

    return { data, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};

export const updateCalculationService = async (
  id_calculation,
  body,
  access
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };

    const { data } = await axios_auth.put(
      `${process.env.REACT_APP_API_URL}/1.0/calculation/${id_calculation}`,
      body,
      config
    );

    return { data, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};

export const deleteCalculationService = async (id_calculation, access) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const { data } = await axios_auth.delete(
      `${process.env.REACT_APP_API_URL}/1.0/calculation/${id_calculation}`,
      config
    );

    return { data, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};

export const getCalculationLogsService = async (access, id, query) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const { data } = await axios_auth.get(
      `${process.env.REACT_APP_API_URL}/1.0/calculation-log/${id}${query}`,
      config
    );

    return { data: data, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};
