import React from "react";
import Layout from "@/components/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useIsAuth from "@/hooks/useIsAuth";
import { logout } from "@/actions/user.actions";
import { Box } from "@mui/material";
import { Card, CardContent, CardActions } from "@mui/material";
import { Button, Typography } from "@mui/material";

function AccountPage() {
  useIsAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_sign_in } = useSelector((state) => state);
  const { user_info } = user_sign_in;

  const handleLogout = (e) => {
    dispatch(logout());
  };

  return (
    <Layout>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 1200,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          {user_info && (
            <>
              <Box sx={{ display: "flex" }}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Información del usuario
                    </Typography>
                    <Typography variant="h5" component="div">
                      {user_info.first_name} {user_info.last_name}
                    </Typography>
                    <Typography color="text.secondary">
                      {user_info.email}
                    </Typography>
                    <Typography color="text.secondary" variant="body1">
                      Mezcal user:{" "}
                      <Typography color="text.primary" component="span">
                        {user_info.username}
                      </Typography>
                    </Typography>
                    <Typography color="text.secondary" variant="body1">
                      Mezcal user ID:{" "}
                      <Typography color="text.primary" component="span">
                        {user_info.id_user}
                      </Typography>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {user_info.is_activated === false && (
                      <Button
                        onClick={() => {
                          navigate("password-reset");
                        }}
                        size="small"
                        variant="outlined"
                        sx={(theme) => ({
                          borderColor: theme.palette.text.brand,
                          color: theme.palette.text.brand,
                          "&:hover": {
                            borderColor: theme.palette.text.secondaryBrand,
                          },
                        })}
                      >
                        Cambiar contraseña
                      </Button>
                    )}
                    <Button
                      fullWidth={user_info.is_activated === true}
                      size="small"
                      variant="outlined"
                      onClick={handleLogout}
                      sx={(theme) => ({
                        borderColor: theme.palette.text.brand,
                        color: theme.palette.text.brand,
                        "&:hover": {
                          borderColor: theme.palette.text.secondaryBrand,
                        },
                      })}
                    >
                      Cerrar sesión
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export default AccountPage;
