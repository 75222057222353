import React from "react";
import Autocomplete from "@/components/CalcSelect/Autocomplete";
import CalcInput from "@/components/CalcInput";
import CalcSelect from "@/components/CalcSelect";
import { Tooltip } from "@mui/material";
import {
  axisDiameterWhenSolid,
  axisDiameterWhenTube,
  findAxisOptionsWhenTube,
} from "../diameter_and_thicknes_content";
import { inputOnly, outputOnly, inputOutputOnly } from "../input_classes";

export const getAxisContent = (bottom_bearing_enabled = false) => {
  return [
    {
      is_visible: true,
      name: "total_absorbed_power",
      label: "Pat Pot abs total kW",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Pract-Pot abs calc tot kW",
      name: "total_calculated_absorbed_power",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Pit-Pot inst total kW",
      name: "total_installed_power",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: true,
      label: "Par resistente Nm (Pat)",
      name: "resistant_torque",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Par cálculo Nm (Pat)",
      name: "calculation_torque",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Par motor Nm (Pit)",
      name: "engine_torque",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: (
        <>
          Watios absb.total/m<sup>3</sup>
        </>
      ),
      name: "total_absorbed_watts",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "G abs.total s-1",
      name: "total_absorbed_gradient",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Cojinete de fondo",
      name: "bottom_bearing",
      type: "CHECKBOX",
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: "Altura total desde apoyos m",
      name: "bb_total_support_height",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: "Altura de cojinete m",
      name: "bearing_height",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: "Long. hasta cojinete m",
      name: "height_to_bearing",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Long. total eje m",
      name: "total_axis_length",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Tipo de eje",
      name: "axis_type",
      type: "CUSTOM",
      getContent: (props) => {
        return (
          <CalcSelect name="axis_type" state_value={props.state_value}>
            <option value="Tubo">Tubo</option>
            <option value="Sólido">Sólido</option>
          </CalcSelect>
        );
      },
      class: inputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Dmtro. eje mm",
      name: "axis_diameter",
      type: "CUSTOM",
      getContent: (props) => {
        let options = [];

        if (props.axis_type === "Tubo") {
          options = axisDiameterWhenTube().diameter_options;
        } else {
          options = axisDiameterWhenSolid();
        }
        return (
          // <></>
          <Autocomplete
            id="axis_diameter"
            table_body_id="table_axis_body"
            state_value={props.state_value ? props.state_value.toString() : ""}
            name={props.name}
            options={options}
          />
        );
      },
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: true,
      label: "Espesor eje mm",
      name: "axis_thickness",
      type: "CUSTOM",
      getContent: (props) => {
        let ath_options = findAxisOptionsWhenTube(props.axis_diameter);
        return (
          <>
            {props.axis_type === "Tubo" ? (
              <Autocomplete
                id="axis_thickness"
                state_value={
                  props.state_value ? props.state_value.toString() : ""
                }
                name="axis_thickness"
                table_body_id="table_axis_body"
                options={ath_options ? ath_options.options : []}
                style={{ ...props.class }}
              />
            ) : (
              <Tooltip title="= Diámetro eje / 2">
                <CalcInput
                  sx={inputOutputOnly}
                  // style={{ ...props.class }}
                  name="axis_thickness"
                  state_value={props.state_value}
                />
              </Tooltip>
            )}
          </>
        );
      },
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: !bottom_bearing_enabled,
      label: "Flecha eje mm/m",
      name: "axis_arrow",
      type: "CUSTOM",
      getContent: (props) => {
        return (
          // <></>
          <CalcInput
            // style={outputOnly}
            sx={props?.class}
            state_value={props?.state_value}
            disabled={true}
            readOnly={true}
            name="axis_arrow"
            message={props.message && props.message}
          />
        );
      },
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: (
        <>
          Cortante kg/mm<sup>2</sup>
        </>
      ),
      name: "cutting",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: (
        <>
          Tensión kg/mm<sup>2</sup>
        </>
      ),
      name: "tension_axis",
      type: "CUSTOM",
      getContent: (props) => {
        return (
          // <></>
          <CalcInput
            // style={outputOnly}
            sx={props?.class}
            state_value={props?.state_value}
            disabled={true}
            readOnly={true}
            name="tension_axis"
            message={props.message && props.message}
          />
        );
      },
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "V. crítica rpm",
      name: "critical_speed",
      type: "CUSTOM",
      getContent: (props) => {
        return (
          // <></>
          <CalcInput
            // style={outputOnly}
            sx={props?.class}
            state_value={props?.state_value}
            disabled={true}
            readOnly={true}
            name="critical_speed"
            message={props.message && props.message}
          />
        );
      },
      class: outputOnly,
      read_only: true,
    },
    // THIS IS A NEW FIELD
    {
      is_visible: true,
      label: "Segundo modo rpm",
      name: "second_vibration_mode",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    // THIS IS A NEW FIELD
    {
      is_visible: true,
      label: "Tercer modo rpm",
      name: "third_vibration_mode",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Peso eje kg",
      name: "axis_weight",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Peso total móvil(es) kg",
      name: "total_propeller_weight",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Peso total núcleo(s) kg",
      name: "total_core_weight",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "Peso total susp. kg",
      name: "total_weight_suspended",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: (
        <>
          Superficie total m<sup>2</sup>
        </>
      ),
      name: "total_area",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "F. radial EQ.daN",
      name: "equivalent_radial_force",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: true,
      label: "F. axial total daN",
      name: "total_axial_force",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: !bottom_bearing_enabled,
      label: "Momento flector Nm",
      name: "bending_moment",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: "Reacc. en cojinete daN",
      name: "bearing_reaction",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: "Reacción en apoyo daN",
      name: "reaction_in_support",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      is_visible: bottom_bearing_enabled,
      label: "Momento en apoyo Nm",
      name: "support_moment",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    // {
    //   is_visible: bottom_bearing_enabled,
    //   label: "Momento en móvil 1 Nm",
    //   name: "",
    //   type: "",
    //   class: outputOnly,
    //   read_only: false,
    // },
  ];
};
