import { refreshToken } from "../actions/user.actions";
import axios from "axios";
import store from "../store";

// Usar esto para los tokens
const axios_auth = axios.create();

async function refreshAuxAction() {
  let result = await store.dispatch(refreshToken());

  return result;
}
axios_auth.interceptors.response.use(
  (response) => response,
  async (err) => {
    const original_req = err.config;
    if (
      err.response.status === 401 &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      return refreshAuxAction()
        .then((data) => {
          const { user_sign_in } = store.getState();

          if (!user_sign_in.user_info) {
            return Promise.reject(data);
          }

          original_req._retry = true; // eslint-disable-line no-underscore-dangle
          original_req.headers[
            "Authorization"
          ] = `Bearer ${user_sign_in.user_info.access}`;

          return axios(original_req);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    return Promise.reject(err);
  }
);

export default axios_auth;
