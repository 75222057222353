export const initialStateAxis = () => {
  return {
    total_absorbed_power: {
      name: "total_absorbed_power",
      type: "",
      value: "",
    },
    total_calculated_absorbed_power: {
      name: "total_calculated_absorbed_power",
      type: "",
      value: "",
    },
    total_installed_power: {
      name: "total_installed_power",
      type: "",
      value: "",
    },
    resistant_torque: {
      name: "resistant_torque",
      type: "",
      value: "",
    },
    calculation_torque: {
      name: "calculation_torque",
      type: "",
      value: "",
    },
    engine_torque: {
      name: "engine_torque",
      type: "",
      value: "",
    },
    total_absorbed_watts: {
      name: "total_absorbed_watts",
      type: "",
      value: "",
    },
    total_absorbed_gradient: {
      name: "total_absorbed_gradient",
      type: "",
      value: "",
    },
    total_axis_length: {
      name: "total_axis_length",
      type: "",
      value: "",
    },
    axis_diameter: {
      name: "axis_diameter",
      type: "",
      value: "",
    },
    axis_thickness: {
      name: "axis_thickness",
      type: "",
      value: "",
    },
    axis_type: {
      name: "axis_type",
      type: "",
      value: "Tubo",
    },
    axis_arrow: {
      name: "axis_arrow",
      type: "",
      value: "",
    },
    tension_axis: {
      name: "tension_axis",
      type: "",
      value: "",
    },
    critical_speed: {
      name: "critical_speed",
      type: "",
      value: "",
    },
    second_vibration_mode: {
      name: "second_vibration_mode",
      type: "",
      value: "",
    },
    third_vibration_mode: {
      name: "third_vibration_mode",
      type: "",
      value: "",
    },
    axis_weight: {
      name: "axis_weight",
      type: "",
      value: "",
    },
    total_propeller_weight: {
      name: "total_propeller_weight",
      type: "",
      value: "",
    },
    total_core_weight: {
      name: "total_core_weight",
      type: "",
      value: "",
    },
    total_weight_suspended: {
      name: "total_weight_suspended",
      type: "",
      value: "",
    },
    total_area: {
      name: "total_area",
      type: "",
      value: "",
    },
    equivalent_radial_force: {
      name: "equivalent_radial_force",
      type: "",
      value: "",
    },
    total_axial_force: {
      name: "total_axial_force",
      type: "",
      value: "",
    },
    bending_moment: {
      name: "bending_moment",
      type: "",
      value: "",
    },
  };
};
