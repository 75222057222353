import React from "react";
import CalcInput from "@/components/CalcInput";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { calculation_constants } from "@/constants/calculation.constants";
import useChangeTable from "@/hooks/useChangeTable";

const TableComments = () => {
  const dispatch = useDispatch();
  const { calculation } = useSelector((state) => state);
  const { table_comments } = calculation;
  const ref = React.useRef();
  const handleChange = (e) => {
    dispatch({
      type: calculation_constants.SET_CALCULATION_COMMENTS,
      payload: e.target.value,
    });
  };
  useChangeTable({ ref, handleChange });
  return (
    <Box sx={{ width: "100%" }} ref={ref}>
      <CalcInput
        state_value={table_comments}
        multiline
        name="comments"
        sx={(theme) => ({
          textarea: {
            width: "100%",
            minHeight: 150,
            padding: "10px",
            fontSize: "12px",
            color: "inherit",
            background: "inherit",
            fontFamily: "inherit",
            outline: "none",
            transition: "all 100ms ease",
            border: "2px solid #d5d5d5",
            "&:focus": {
              border: `2px solid ${
                theme.palette.mode === "dark"
                  ? theme.palette.text.primary
                  : "black"
              }`,
            },
          },
        })}
      />
    </Box>
  );
};

export default TableComments;
