export const getWorkConditionsContent = () => {
  return [
    {
      label: "N rpm",
      name: "n_rpm",
    },
    {
      label: (
        <>
          Densidad kg/m<sup>3</sup>
        </>
      ),
      name: "density_kg_m_3",
    },
    {
      label: "Viscosidad cps",
      name: "viscosity_cps",
    },
  ];
};
