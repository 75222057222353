import React from "react";
import TablePagination from "@mui/material/TablePagination";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

function Pagination() {
  const reports = useSelector((state) => state.reports);
  const navigate = useNavigate();
  const location = useLocation();
  const { reports_data, loading_reports, error_reports } = reports;

  const handleChangePage = (e, new_page) => {
    let query = "/reports?";
    const query_params = new URLSearchParams(location.search);
    if (query_params.has("page")) {
      query_params.set("page", new_page + 1);
    } else {
      query_params.append("page", new_page + 1);
    }
    if (new_page === 0) {
      query_params.delete("page");
    }
    query += query_params.toString();
    navigate(query);
  };

  return (
    <>
      {reports_data.count && !loading_reports && !error_reports && (
        <TablePagination
          component="div"
          count={reports_data.count}
          page={reports_data.current_page - 1}
          onPageChange={handleChangePage}
          //   onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={reports_data.per_page}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  );
}

export default Pagination;
