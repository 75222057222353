import axios_auth from "../middlewares/refresh.middleware";

export const getPDFPreviewService = async (id_calculation, access) => {
  try {
    const config = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const { data } = await axios_auth.get(
      `${process.env.REACT_APP_API_URL}/1.0/generate_pdf/${id_calculation}`,
      config
    );

    const file = new Blob([data], { type: "application/pdf" });
    const file_url = URL.createObjectURL(file);

    // Buscamos el div que contiene al pdf y le añadimos el object con el blob
    // convertido a url
    var div_pdf_preview = document.getElementById("pdf_preview_container");

    if (div_pdf_preview.hasChildNodes()) {
      div_pdf_preview.removeChild(div_pdf_preview.lastChild);
    }
    var object = document.createElement("object");
    object.className = "reports__pdf-preview";
    object.data = file_url;
    div_pdf_preview.appendChild(object);

    return { data: {}, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};
export const getPDFDownloadService = async (id_calculation, access) => {
  try {
    const config = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const { data } = await axios_auth.get(
      `${process.env.REACT_APP_API_URL}/1.0/generate_pdf/${id_calculation}`,
      config
    );

    const file = new Blob([data], { type: "application/pdf" });
    const file_url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = file_url;
    link.setAttribute("download", `Report ${id_calculation}`);
    document.body.appendChild(link);
    link.click();

    return { data: {}, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};
