import React from "react";

const useChangeTable = ({ ref = null, handleChange = () => {} }) => {
  React.useEffect(() => {
    ref.current.addEventListener("change", handleChange);
    let ref_aux = ref.current;
    return () => {
      // unsubscribe event
      ref_aux.removeEventListener("change", handleChange);
    };
  });
};

export default useChangeTable;
