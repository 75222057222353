import { reports_constants } from "../constants/reports.constants";
import { getReportsService } from "../services/reports.service";
import { deleteCalculationService } from "../services/calculation.service";
import {
  getPDFPreviewService,
  getPDFDownloadService,
} from "../services/pdf.service";

export const getReports =
  ({ query = "", page = null }) =>
  async (dispatch, getState) => {
    const {
      user_sign_in: { user_info },
      reports: { query_params },
    } = getState();
    if (!user_info) {
      return;
    }
    if (page !== null) {
      if (query === "") {
        query += `?page=${page}`;
      } else {
        query += `&page=${page}`;
      }
    }

    if (query_params.show_users) {
      if (query === "") {
        query += `?show_users=${query_params.show_users}`;
      } else {
        query += `&show_users=${query_params.show_users}`;
      }
    }

    dispatch({
      type: reports_constants.REPORTS_REQUEST,
    });
    const { data, success } = await getReportsService(user_info.access, query);

    if (success) {
      dispatch({
        type: reports_constants.REPORTS_REQUEST_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: reports_constants.REPORTS_REQUEST_FAIL,
        payload: data.message
          ? data.message
          : data.detail
          ? data.detail
          : "Something went wrong",
      });
    }
  };

export const getPDFPreview = (id_calculation) => async (dispatch, getState) => {
  const {
    user_sign_in: { user_info },
  } = getState();
  if (!user_info) {
    return;
  }

  // Para remover el pdf actual que tiene si es que tiene.
  let div_pdf_preview = document.getElementById("pdf_preview_container");
  if (div_pdf_preview.hasChildNodes()) {
    div_pdf_preview.removeChild(div_pdf_preview.lastChild);
  }

  dispatch({
    type: reports_constants.REPORTS_PDF_REQUEST,
  });

  const { success } = await getPDFPreviewService(
    id_calculation,
    user_info.access
  );

  if (success) {
    dispatch({
      type: reports_constants.REPORTS_PDF_REQUEST_SUCCESS,
    });
  } else {
    dispatch({
      type: reports_constants.REPORTS_PDF_REQUEST_FAIL,
      payload: "Algo salió mal",
      // error.response && error.response.data.message
      //   ? error.response.data.message
      //   : error.message,
    });
  }
};

export const getPDFDownload =
  (id_calculation) => async (dispatch, getState) => {
    const {
      user_sign_in: { user_info },
    } = getState();
    if (!user_info) {
      return;
    }

    dispatch({
      type: reports_constants.REPORTS_PDF_DOWNLOAD_REQUEST,
    });

    const { success } = await getPDFDownloadService(
      id_calculation,
      user_info.access
    );

    if (success) {
      dispatch({
        type: reports_constants.REPORTS_PDF_DOWNLOAD_REQUEST_SUCCESS,
      });
    } else {
      dispatch({
        type: reports_constants.REPORTS_PDF_DOWNLOAD_REQUEST_FAIL,
        payload: "Algo salió mal",
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      });
    }
  };

export const getReportById = (id_calculation) => async (dispatch, getState) => {
  const {
    user_sign_in: { user_info },
  } = getState();
  if (!user_info) {
    return;
  }
};

export const deleteCalculation =
  (id_calculation) => async (dispatch, getState) => {
    const {
      user_sign_in: { user_info },
    } = getState();
    if (!user_info) {
      return;
    }

    dispatch({
      type: reports_constants.DELETE_CALCULATION_BY_ID_REQUEST,
    });

    const { success } = await deleteCalculationService(
      id_calculation,
      user_info.access
    );

    if (success) {
      dispatch({
        type: reports_constants.DELETE_CALCULATION_BY_ID_REQUEST_SUCCESS,
      });
      dispatch(getReports({ query: "", page: null }));
    } else {
      dispatch({
        type: reports_constants.DELETE_CALCULATION_BY_ID_REQUEST_FAIL,
        payload: "Algo salió mal",
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      });
    }
  };
