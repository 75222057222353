import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";
import { getBottomSweepContent } from "@/helpers/tables_content/bottom_sweep_content";
import CalcInput from "@/components/CalcInput";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import useChangeTable from "@/hooks/useChangeTable";
import { useSelector, useDispatch } from "react-redux";
import { calculateChange } from "@/actions/calculation.actions";
import getClassDependsOn from "@/helpers/getClassDependsOn";

function TableBottomSweep() {
  // const [show, setShow] = React.useState(true);
  const rows = getBottomSweepContent();
  const ref = React.useRef();
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.calculation);
  const { table_bottom_sweep, error } = calculation;

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name == "bottom_sweep_enabled") {
      value = !table_bottom_sweep.bottom_sweep_enabled.value;
    }

    dispatch(
      calculateChange({
        belongs_to: "table_bottom_sweep",
        name: e.target.name,
        value: value,
      })
    );
  };
  useChangeTable({ ref, handleChange });
  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="table bottom sweep">
        <TableBody ref={ref} id="table_bottom_sweep_body">
          <TableRow
            sx={(theme) => ({
              backgroundColor: `${theme.palette.action.hover} !important`,
            })}
          >
            <TableCell colSpan={2} align="center" sx={{ padding: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={table_bottom_sweep.bottom_sweep_enabled.value}
                    name="bottom_sweep_enabled"
                    sx={(theme) => ({
                      color: theme.palette.text.primary,
                      "&.Mui-checked": {
                        color: theme.palette.text.primary,
                      },
                    })}
                    size="small"
                  />
                }
                // onChange={handleChange}
                componentsProps={{ typography: { fontSize: 14 } }}
                label="Barrido de fondo"
              />
            </TableCell>
          </TableRow>
          {table_bottom_sweep.bottom_sweep_enabled.value &&
            rows.map((row, index) => {
              if (row.type === "HEADER") {
                return (
                  <React.Fragment key={index}>
                    <TableRow
                      sx={{
                        "&:hover": {
                          backgroundColor: "unset",
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{
                          padding: 1,
                          span: { fontSize: 12, fontWeight: 600 },
                        }}
                        colSpan={2}
                      >
                        <span>{row.label}</span>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell
                      padding="none"
                      align="left"
                      sx={{ padding: "0 10px" }}
                    >
                      <label>{row.label}</label>
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="left"
                      sx={getClassDependsOn(
                        error.errors_in,
                        row.name,
                        true,
                        row.class
                      )}
                    >
                      <CalcInput
                        disabled={row.read_only}
                        readOnly={row.read_only}
                        name={row.name}
                        state_value={
                          table_bottom_sweep[row.name].rounded_value
                            ? table_bottom_sweep[row.name].rounded_value
                            : table_bottom_sweep[row.name].value
                        }
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableBottomSweep;
