import React from "react";
import "./App.css";
import CalculationPage from "@/pages/CalculationPage";
import ReportsPage from "@/pages/ReportsPage";
import SignInPage from "@/pages/SigninPage";
import AccountPage from "@/pages/AccountPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { grey } from "@mui/material/colors";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    text: {
      ...(mode === "light"
        ? {
            primary: "#000",
            secondary: grey[800],
            brand: "hsl(180, 41%, 57%)",
            secondaryBrand: "#7af6ff",
          }
        : {
            primary: grey[400],
            secondary: grey[500],
            brand: "hsl(180, 41%, 57%)",
            secondaryBrand: "#7af6ff",
          }),
    },
  },
});

function App() {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    // () => createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light")),
    // () => createTheme(adaptV4Theme(getDesignTokens("dark")))
    () => createTheme(adaptV4Theme(getDesignTokens("light")))
    // [prefersDarkMode]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="*" element={<div>404</div>} />
            <Route path="/" element={<CalculationPage />} />
            <Route
              path="/account/password-reset"
              element={<PasswordResetPage />}
            />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/calculation/:id" element={<CalculationPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
