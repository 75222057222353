export const axisDiameterWhenSolid = () => {
  return [
    "22.225",
    "25.4",
    "31.75",
    "38.1",
    "41.275",
    "44.45",
    "50.8",
    "57.15",
    "63.5",
    "69.85",
    "76.2",
    "82.55",
    "88.9",
    "95.25",
    "101.6",
  ];
};

export const axisDiameterWhenTube = () => {
  return {
    diameter_options: [
      "60.3",
      "73",
      "88.9",
      "114.3",
      "141.3",
      "168.3",
      "219.1",
      "273",
    ],
    axis_options: [
      {
        value: "60.3",
        default: "3.91",
        options: ["3.91", "5.54"],
      },
      {
        value: "73",
        default: "5.16",
        options: ["5.16", "7.01"],
      },
      {
        value: "88.9",
        default: "5.49",
        options: ["5.49", "7.62"],
      },
      {
        value: "114.3",
        default: "6.02",
        options: ["6.02", "8.56"],
      },
      {
        value: "141.3",
        default: "6.55",
        options: ["6.55", "9.52"],
      },
      {
        value: "168.3",
        default: "7.11",
        options: ["7.11", "10.97"],
      },
      {
        value: "219.1",
        default: "8.18",
        options: ["8.18", "12.7"],
      },
      {
        value: "273",
        default: "9.27",
        options: ["9.27", "12.7"],
      },
    ],
  };
};

export const findAxisOptionsWhenTube = (diameter_value) => {
  let options = axisDiameterWhenTube().axis_options;
  let aux = options.find((item) => item.value === diameter_value);

  return aux;
};
