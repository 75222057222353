import { inputOnly, outputOnly } from "../input_classes";

export const getContainerTypeContent = (
  container_type = "Contenedor cilíndrico"
) => {
  let is_quadrangular = container_type === "Cuadrangular";
  return [
    {
      is_visible: is_quadrangular,
      label: "Ancho m",
      name: "container_width",
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: is_quadrangular,
      label: "Largo m",
      name: "container_length",
      class: inputOnly,
      read_only: false,
    },
    {
      is_visible: true,
      label: "Diámetro m",
      name: "container_diameter",
      class: is_quadrangular ? outputOnly : inputOnly,
      read_only: is_quadrangular,
    },
    {
      is_visible: true,
      label: "Altura m",
      name: "container_height",
      class: inputOnly,
      read_only: false,
    },
  ];
};
