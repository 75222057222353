import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";
import { useSelector, useDispatch } from "react-redux";
import CalcInput from "@/components/CalcInput";
import { calculation_constants } from "@/constants/calculation.constants";
import useChangeTable from "@/hooks/useChangeTable";

const getRows = () => {
  let aux = [];
  for (let i = 0; i < 15; i++) {
    aux.push(i);
  }
  return aux;
};

function TableDetail() {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const { user_sign_in, calculation } = useSelector((state) => state);
  const { user_info } = user_sign_in;
  const rows = getRows();
  const { table_detail } = calculation;

  const handleChange = (e) => {
    if (e.target.dataset.indexNumber == undefined) {
      dispatch({
        type: calculation_constants.SET_DETAIL_PROP,
        payload: { name: e.target.name, value: e.target.value },
      });
    } else {
      dispatch({
        type: calculation_constants.SET_DETAIL,
        payload: {
          name: e.target.name,
          value: e.target.value,
          detail_position: e.target.dataset.indexNumber,
        },
      });
    }
  };
  useChangeTable({ ref, handleChange });

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="table details" ref={ref}>
        <TableHead>
          <TableRow
            sx={(theme) => ({
              borderBottom:
                theme.palette.mode === "dark"
                  ? "1px solid #333333"
                  : "1px solid #f5f5f5",
            })}
          >
            <TableCell
              sx={{
                padding: "0 10px !important",
                boxShadow: "unset !important",
              }}
            >
              Modelo
            </TableCell>
            <TableCell
              sx={(theme) => ({
                bgcolor: "transparent !important",
                color: `${
                  theme.palette.mode === "dark"
                    ? theme.palette.common.primary
                    : theme.palette.common.black
                } !important`,
                padding: "0 !important",
                boxShadow: "unset !important",
              })}
              colSpan={2}
            >
              <CalcInput
                name="detail_model"
                state_value={table_detail.detail_model}
              ></CalcInput>
            </TableCell>
          </TableRow>
          <TableRow
            sx={(theme) => ({
              borderBottom:
                theme.palette.mode === "dark"
                  ? "1px solid #333333"
                  : "1px solid #f5f5f5",
            })}
          >
            <TableCell
              sx={{
                padding: "0 10px !important",
                boxShadow: "unset !important",
              }}
            >
              Calcula
            </TableCell>
            <TableCell
              sx={(theme) => ({
                fontWeight: "500 !important",
                bgcolor: "transparent !important",
                color: `${
                  theme.palette.mode === "dark"
                    ? theme.palette.common.white
                    : theme.palette.text.primary
                } !important`,
                padding: "0 .5em !important",
                boxShadow: "unset !important",
              })}
              colSpan={2}
            >
              {calculation.edit.is_edit ? (
                <>
                  {calculation.edit.data.user &&
                    calculation.edit.data.user.username}
                </>
              ) : (
                <>{user_info && user_info.username}</>
              )}
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              td: "none !important",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)",
            }}
          >
            <TableCell>Componente</TableCell>
            <TableCell padding="none">Detalle</TableCell>
            <TableCell padding="none">Costo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((index) => {
            let detail = table_detail.details.find(
              (el) => el.detail_position == index
            );
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell padding="none">
                    <CalcInput
                      name="detail_name"
                      state_value={detail && detail.detail_name}
                      componentsProps={{
                        input: {
                          "data-index-number": index,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell padding="none" align="right">
                    <CalcInput
                      name="detail"
                      state_value={detail && detail.detail}
                      componentsProps={{
                        input: {
                          "data-index-number": index,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell padding="none" align="right">
                    <CalcInput
                      name="detail_price"
                      state_value={detail && detail.detail_price}
                      componentsProps={{
                        input: {
                          "data-index-number": index,
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
          <TableRow>
            <TableCell />
            <TableCell padding="none" align="right" sx={{ padding: "0 10px" }}>
              <span>Costo total</span>
            </TableCell>
            <TableCell padding="none" align="right">
              <CalcInput
                name="detail_total_cost"
                state_value={table_detail.detail_total_cost}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableDetail;
