export const reports_constants = {
  REPORTS_REQUEST: "REPORTS_REQUEST",
  REPORTS_REQUEST_SUCCESS: "REPORTS_REQUEST_SUCCESS",
  REPORTS_REQUEST_FAIL: "REPORTS_REQUEST_FAIL",

  REPORTS_PDF_REQUEST: "REPORTS_PDF_REQUEST",
  REPORTS_PDF_REQUEST_SUCCESS: "REPORTS_PDF_REQUEST_SUCCESS",
  REPORTS_PDF_REQUEST_FAIL: "REPORTS_PDF_REQUEST_FAIL",

  REPORTS_PDF_DOWNLOAD_REQUEST: "REPORTS_PDF_DOWNLOAD_REQUEST",
  REPORTS_PDF_DOWNLOAD_REQUEST_SUCCESS: "REPORTS_PDF_DOWNLOAD_REQUEST_SUCCESS",
  REPORTS_PDF_DOWNLOAD_REQUEST_FAIL: "REPORTS_PDF_DOWNLOAD_REQUEST_FAIL",

  SET_CURRENT_ROW_ID: "SET_CURRENT_ROW_ID",

  SET_REPORTS_ORDERING_BY_ID: "SET_REPORTS_ORDERING_BY_ID",

  DELETE_CALCULATION_BY_ID_REQUEST: "DELETE_CALCULATION_BY_ID_REQUEST",
  DELETE_CALCULATION_BY_ID_REQUEST_SUCCESS:
    "DELETE_CALCULATION_BY_ID_REQUEST_SUCCESS",
  DELETE_CALCULATION_BY_ID_REQUEST_FAIL:
    "DELETE_CALCULATION_BY_ID_REQUEST_FAIL",
};
