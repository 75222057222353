const formatDate = ({
  date,
  options = { year: "numeric", month: "numeric", day: "numeric" },
}) => {
  // if (!string) return date.toLocaleDateString("locale", options);
  let d = new Date(date);
  // day = "" + d.getDate(),
  // month = "" + (d.getMonth() + 1),
  // year = d.getFullYear();
  let res = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;

  return res;
};

export default formatDate;
