import axios from "axios";
import axios_auth from "../middlewares/refresh.middleware";
import store from "../store";
export const refreshTokensService = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 5000,
    };
    let {
      user_sign_in: {
        user_info: { refresh },
      },
    } = store.getState();
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh/`,
      { refresh },
      config
    );
    return { data: data, success: true, status: 200 };
  } catch (error) {
    // En caso de fallar el refresh voy a enviar el refresh token vacio
    return {
      data: {},
      success: false,
      status: error.response?.status,
    };
  }
};
export const getTokensService = async (email, password) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 5000,
    };
    const body = {
      email,
      password,
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/signin/`,
      body,
      config
    );

    return { data: data, success: true, status: 200 };
  } catch (error) {
    // if (error.response == null) {
    //   return { data: { success: false } };
    // }
    return {
      data: error,
      success: false,
      status: error.response?.status,
    };
  }
};

export const passwordResetService = async (access, email) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const body = {
      email,
    };
    const { data } = await axios_auth.post(
      `${process.env.REACT_APP_API_URL}/auth/password-reset`,
      body,
      config
    );
    return { data: data, success: true, status: 200 };
  } catch (error) {
    return {
      data: error,
      success: false,
      status: error.response?.status,
    };
  }
};
