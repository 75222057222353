import React from "react";
import { Tooltip } from "@mui/material";
import CalcInput from "@/components/CalcInput";
import CalcSelect from "@/components/CalcSelect";
import { inputOnly, outputOnly, inputOutputOnly } from "../input_classes";
import Autocomplete from "@/components/CalcSelect/Autocomplete";
// import { PropellerIcons } from "../../components/InteractiveIcons/PropellerIcons";

export const getPropellerContent = () => {
  return [
    {
      name: "mobile_type",
      label: "Tipo de móvil",
      class: inputOnly,
      type: "CUSTOM",
      read_only: false,
      getContent: (props) => {
        return (
          <CalcSelect
            inputProps={{ "data-index-number": props.index }}
            name={props?.name}
            state_value={props?.state_value}
          >
            <option value="Ninguno">Ninguno</option>
            <option value="Gamma">Gamma</option>
            <option value="Stokes">Stokes</option>
            <option value="Nabla">Nabla</option>
            <option value="Sabre">Sabre</option>
            <option value="Doble flujo">Doble flujo</option>
            <option value="Cowles">Cowles</option>
            <option value="Rot. Estat">Rot. Estat</option>
            <option value="Turbina">Turbina</option>
            <option value="Ancla">Ancla</option>
            <option value="U">U</option>
            <option value="V">V</option>
          </CalcSelect>
        );
      },
    },
    {
      name: "propeller_diameter",
      label: "Diámetro móvil m",
      class: inputOnly,
      type: "",
      read_only: false,
    },
    {
      label: "Número de palas",
      name: "number_of_blades",
      class: inputOnly,
      type: "",
      read_only: false,
    },
    {
      label: "Inclinación de palas°",
      name: "blade_inclination",
      class: inputOnly,
      type: "",
      read_only: false,
    },
    {
      label: "Ancho de pala m",
      name: "blade_width",
      class: inputOnly,
      type: "",
      read_only: false,
    },
    {
      label: "Altura de pala m",
      name: "blade_height",
      class: inputOnly,
      type: "",
      read_only: false,
    },
    {
      label: "Sentido de flujo",
      name: "flow_direction",
      class: inputOnly,
      type: "CUSTOM",
      read_only: false,
      getContent: (props) => {
        return (
          <CalcSelect
            inputProps={{ "data-index-number": props.index }}
            disabled={!props?.is_enabled}
            sx={props?.class}
            name={props?.name}
            state_value={props?.state_value}
          >
            <option value=""></option>
            <option value="Impulsante">Impulsante</option>
            <option value="Aspirante">Aspirante</option>
          </CalcSelect>
        );
      },
    },
    {
      label: "d/D",
      name: "d_d",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Pot.abs. kW",
      name: "absorbed_power",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Pot.abs.calc. kW",
      name: "absorbed_power_calc",
      class: inputOutputOnly,
      type: "CUSTOM",
      read_only: false,
      getContent: (props) => {
        return (
          <>
            {props.equals_on && props.is_enabled ? (
              <Tooltip title={`=Pot. abs. * ${props.multiplier}`}>
                <CalcInput
                  componentsProps={{
                    input: { "data-index-number": props.index },
                  }}
                  sx={props.class}
                  readOnly={!props.is_enabled}
                  disabled={!props.is_enabled}
                  name={props.name}
                  state_value={props.state_value}
                />
              </Tooltip>
            ) : (
              <CalcInput
                componentsProps={{
                  input: { "data-index-number": props.index },
                }}
                sx={props.class}
                readOnly={!props.is_enabled}
                disabled={!props.is_enabled}
                name={props.name}
                state_value={props.state_value}
              />
            )}
          </>
        );
      },
    },
    {
      label: (
        <>
          Caudal axial m<sup>3</sup>/h
        </>
      ),
      name: "axial_flow",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Renov/hora",
      name: "renovations_per_hour",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Vel. flujo m/s",
      name: "flow_speed",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Vel. periférica m/s",
      name: "peripheral_speed",
      class: outputOnly,
      type: "CUSTOM",
      read_only: true,
      getContent: (props) => {
        return (
          // <></>
          <CalcInput
            // style={outputOnly}
            sx={props?.class}
            state_value={props?.state_value}
            disabled={true}
            readOnly={true}
            name="axis_arrow"
            message={props.message && props.message}
          />
        );
      },
    },
    {
      label: "No. Reynolds",
      name: "reynolds_number",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Intensidad",
      name: "intensity",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Intensidad corregida",
      name: "corrected_intensity",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Esp. hle. mm (Pac)",
      name: "propeller_thickness",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Esp. real mm",
      name: "real_thickness",
      type: "CUSTOM",
      getContent: (props) => {
        let options = [
          3.175, 4.7625, 6.35, 7.9375, 9.525, 12.7, 15.875, 19.05, 25.4, 31.75,
          38.1, 44.45, 50.8,
        ];
        return (
          // <></>
          <Autocomplete
            disabled={!props.is_enabled}
            id="axis_diameter"
            index={props.index}
            state_value={props.state_value ? props.state_value.toString() : ""}
            name={props.name}
            table_body_id="table_propeller_body"
            options={options}
          />
        );
      },
      class: inputOnly,
      read_only: false,
    },
    {
      label: "Peso móvil kg",
      name: "propeller_weight",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Peso núcleo kg",
      name: "core_weight",
      class: inputOnly,
      type: "",
      read_only: false,
    },
    {
      label: "F. radial daN",
      name: "radial_force",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "F. axial daN",
      name: "axial_force",
      class: outputOnly,
      type: "",
      read_only: true,
    },
    {
      label: "Long. hasta brida m",
      name: "length_to_flange",
      class: inputOnly,
      type: "",
      read_only: false,
    },
  ];
};
