export const initialStatePropeller = () => {
  return {
    mobile_type: {
      name: "mobile_type",
      value: "Ninguno",
      is_enabled: true,
    },
    propeller_diameter: {
      name: "propeller_diameter",
      value: "",
      is_enabled: false,
    },
    number_of_blades: {
      name: "number_of_blades",
      value: "",
      is_enabled: false,
    },
    blade_inclination: {
      name: "blade_inclination",
      value: "",
      is_enabled: false,
    },
    blade_width: {
      name: "blade_width",
      value: "",
      is_enabled: false,
    },
    blade_height: {
      name: "blade_height",
      value: "",
      is_enabled: false,
    },
    flow_direction: {
      name: "flow_direction",
      value: "",
      is_enabled: false,
    },
    d_d: {
      name: "d_d",
      value: "",
      is_enabled: false,
    },
    absorbed_power: {
      name: "absorbed_power",
      value: "",
      is_enabled: false,
    },
    absorbed_power_calc: {
      name: "absorbed_power_calc",
      value: "",
      equals_on: false,
      multiplier: "",
      type: "CUSTOM",
      is_enabled: false,
    },
    axial_flow: {
      name: "axial_flow",
      value: "",
      is_enabled: false,
    },
    renovations_per_hour: {
      name: "renovations_per_hour",
      value: "",
      is_enabled: false,
    },
    flow_speed: {
      name: "flow_speed",
      value: "",
      is_enabled: false,
    },
    peripheral_speed: {
      name: "peripheral_speed",
      value: "",
      is_enabled: false,
    },
    reynolds_number: {
      name: "reynolds_number",
      value: "",
      is_enabled: false,
    },
    intensity: {
      name: "intensity",
      value: "",
      is_enabled: false,
    },
    corrected_intensity: {
      name: "corrected_intensity",
      value: "",
      is_enabled: false,
    },
    propeller_thickness: {
      name: "propeller_thickness",
      value: "",
      is_enabled: false,
    },
    real_thickness: {
      name: "real_thickness",
      value: "",
      is_enabled: false,
    },
    propeller_weight: {
      name: "propeller_weight",
      value: "",
      is_enabled: false,
    },
    core_weight: {
      name: "core_weight",
      value: "",

      is_enabled: false,
    },
    radial_force: {
      name: "radial_force",
      value: "",

      is_enabled: false,
    },
    axial_force: {
      name: "axial_force",
      value: "",
      is_enabled: false,
    },
    length_to_flange: {
      name: "length_to_flange",
      value: "",

      is_enabled: false,
    },
    propeller_moment: {
      name: "propeller_moment",
      value: "",
      is_enabled: false,
    },
  };
};
