import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";
import { getAxisContent } from "@/helpers/tables_content/axis_content";
import CalcInput from "@/components/CalcInput";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import useChangeTable from "@/hooks/useChangeTable";
import { onAxisDiameterAxisThicknessAxisTypeChange } from "@/actions/calculation.actions";
import { useSelector, useDispatch } from "react-redux";
import { calculateChange } from "@/actions/calculation.actions";
import getClassDependsOn from "@/helpers/getClassDependsOn";
import { outputOnly } from "@/helpers/input_classes";
function TableWorkConditions() {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.calculation);
  const { table_axis, table_bottom_bearing, error } = calculation;
  const rows = getAxisContent(table_bottom_bearing.bottom_bearing.value);

  const handleChange = (e) => {
    if (
      e.target.name === "axis_diameter" ||
      e.target.name === "axis_thickness" ||
      e.target.name === "axis_type"
    ) {
      dispatch(
        onAxisDiameterAxisThicknessAxisTypeChange({
          name: e.target.name,
          value: e.target.value,
        })
      );
      // Si el evento fue hecho de manera manual
    } else if (e.data) {
      dispatch(
        onAxisDiameterAxisThicknessAxisTypeChange({
          name: e.data.name,
          value: e.data.value,
        })
      );
    } else {
      let table_name = "table_axis";
      let value = e.target.value;
      if (e.target.name in table_bottom_bearing) {
        table_name = "table_bottom_bearing";
        if (e.target.name == "bottom_bearing") {
          value = !table_bottom_bearing.bottom_bearing.value;
        }
      }
      dispatch(
        calculateChange({
          belongs_to: table_name,
          name: e.target.name,
          value: value,
        })
      );
    }
  };
  useChangeTable({ ref, handleChange });

  return (
    <TableContainer>
      <Table aria-label="table axis">
        <TableBody ref={ref} id="table_axis_body">
          {rows.map((row) => {
            if (row.type === "CHECKBOX") {
              return (
                <React.Fragment key={row.name}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ padding: "10px", fontSize: 13 }}
                      colSpan={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={(theme) => ({
                              color: theme.palette.text.primary,
                              "&.Mui-checked": {
                                color: theme.palette.text.primary,
                              },
                            })}
                            name="bottom_bearing"
                            checked={table_bottom_bearing.bottom_bearing.value}
                            size="small"
                          />
                        }
                        componentsProps={{ typography: { fontSize: 14 } }}
                        label={row.label}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            }
            let table = table_axis;
            if (row.name in table_bottom_bearing) {
              table = table_bottom_bearing;
            }

            let field_value = "";
            field_value = table[row.name].value;
            if (table[row.name].rounded_value) {
              field_value = table[row.name].rounded_value;
            }
            let read_only = row.read_only;
            let origin_class = row.class;
            let props = {};
            if (row.type === "CUSTOM") {
              props = {
                message: table[row.name].message && table[row.name].message,
                class: getClassDependsOn(
                  error.errors_in,
                  row.name,
                  true,
                  origin_class
                ),
                name: row.name,
                state_value: field_value,
              };
            }

            if (row.name === "axis_diameter") {
              props.axis_type = table.axis_type.value
                ? table.axis_type.value.toString()
                : "";
            } else if (row.name === "axis_thickness") {
              props.axis_type = table.axis_type.value
                ? table.axis_type.value.toString()
                : "";
              props.axis_diameter = table.axis_diameter.value
                ? table.axis_diameter.value.toString()
                : "";
            }
            return (
              <React.Fragment key={row.name}>
                {row.is_visible && (
                  <TableRow>
                    <TableCell padding="none" sx={{ padding: "0 10px" }}>
                      <label>{row.label}</label>
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="right"
                      sx={getClassDependsOn(
                        error.errors_in,
                        row.name,
                        true,
                        origin_class
                      )}
                    >
                      {row.type === "CUSTOM" ? (
                        row.getContent(props)
                      ) : (
                        <CalcInput
                          disabled={read_only}
                          readOnly={read_only}
                          name={row.name}
                          state_value={field_value}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
          {table_bottom_bearing.bottom_bearing.value == true &&
            table_bottom_bearing.mobile_moment_n.value !== "" && (
              <>
                {table_bottom_bearing.mobile_moment_n.value.map((val, i) => {
                  return (
                    <TableRow>
                      <TableCell padding="none" sx={{ padding: "0 10px" }}>
                        <label>Momento en móvil {i + 1} Nm</label>
                      </TableCell>
                      <TableCell
                        padding="none"
                        sx={getClassDependsOn(
                          error.errors_in,
                          "mobile_moment_n",
                          true,
                          outputOnly
                        )}
                      >
                        <CalcInput
                          disabled={true}
                          readOnly={true}
                          name={"mobile_moment_n"}
                          state_value={val}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableWorkConditions;
