const inputOnly = {
  backgroundColor: "transparent",
};
const outputOnly = {
  color: "#272727",
  backgroundColor: "#d5d5d5",
};
const inputOutputOnly = {
  color: "#000019",
  backgroundColor: "#d4eefa",
};
const inputError = {
  color: "#ae0000",
  backgroundColor: "#ffcccc",
};

const disabledOnly = {
  color: "#101010",
  backgroundColor: "#555555",
};

export { inputOnly, outputOnly, inputOutputOnly, inputError, disabledOnly };

function getClassDependsOn(
  errors_in,
  name,
  is_enabled,
  origin_class,
  position
) {
  if (!position) {
    position = 0;
  }
  let class_response = origin_class;

  let has_an_error = name in errors_in;
  if (!is_enabled) {
    class_response = disabledOnly;
  } else if (
    has_an_error &&
    is_enabled &&
    errors_in[name]?.position == position
  ) {
    class_response = inputError;
  }
  return class_response;
}

export default getClassDependsOn;
