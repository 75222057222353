export const initialStateGearUnits = () => {
  return {
    c: {
      name: "c",
      value: "",
    },
    coaxial_sew_feqn: {
      name: "coaxial_sew_feqn",
      value: "",
    },
    coaxial_nord_feqn: {
      name: "coaxial_nord_feqn",
      value: "",
    },
    parallel_sew_feqn: {
      name: "parallel_sew_feqn",
      value: "",
    },
    parallel_nord_feqn: {
      name: "parallel_nord_feqn",
      value: "",
    },
  };
};
