import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCalculationLogs } from "@/actions/calculation.actions";

import moment from "moment";

import { Box, Stack, Typography, useMediaQuery } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircleIcon from "@mui/icons-material/Circle";

import InfiniteScroll from "react-infinite-scroll-component";
import { getWorkConditionsContent } from "@/helpers/tables_content/work_conditions";
import { getPropellerContent } from "@/helpers/tables_content/propellers_content";
import { getAxisContent } from "@/helpers/tables_content/axis_content";
import { getBottomSweepContent } from "@/helpers/tables_content/bottom_sweep_content";
import { getContainerTypeContent } from "@/helpers/tables_content/container_type_content";
import { getMixerFeaturesContent } from "@/helpers/tables_content/mixer_features";

const change_types = {
  add: "success",
  change: "info",
  delete: "error",
};

const contentTransformed = [
  ...getWorkConditionsContent(),
  ...getPropellerContent(),
  ...getAxisContent(),
  ...getContainerTypeContent(),
  ...getBottomSweepContent(),
  ...getMixerFeaturesContent(),
].reduce((accumulator, currentValue) => {
  accumulator[currentValue.name] = {
    label: currentValue.label,
    name: currentValue.name,
  };
  return accumulator;
}, {});

contentTransformed["propeller_number"] = {
  label: "Número de hélice",
  name: "propeller_number",
};
contentTransformed["detail_model"] = {
  label: "Nombre del modelo",
  name: "detail_model",
};
contentTransformed["comments"] = {
  label: "Comentarios",
  name: "comments",
};

const ChangeRows = ({ data }) => {
  if (data.length < 1) return <></>;
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Campo</TableCell>
          <TableCell align="center">-</TableCell>
          <TableCell align="center">+</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <React.Fragment key={`calc_change_${item.name}${index}`}>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell>{contentTransformed[item.name].label}</TableCell>
              {item.name === "comments" ? (
                <TableCell colSpan={2}>
                  <Typography component={"i"} sx={{ fontSize: 11 }}>
                    No implementado
                  </Typography>
                </TableCell>
              ) : (
                <>
                  <TableCell align="right">
                    <Chip
                      sx={{ borderRadius: 1, fontSize: 11 }}
                      label={
                        item.name === "propeller_number"
                          ? item.old_value + 1
                          : item.old_value
                      }
                      size="small"
                    />
                  </TableCell>

                  <TableCell align="right">
                    <Chip
                      sx={{ borderRadius: 1, fontSize: 11 }}
                      label={
                        item.name === "propeller_number"
                          ? item.new_value + 1
                          : item.new_value
                      }
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

const HistoryRow = ({
  children,
  collapse = false,
  cellsContent = ["Cell"],
  reverseCollapse = false,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {cellsContent.map((cell, index) => (
          <React.Fragment key={`history_row_${index}`}>
            <TableCell>
              {collapse && index === 0 && !reverseCollapse && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
              {cell}
              {collapse &&
                index === cellsContent.length - 1 &&
                reverseCollapse && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
      {collapse && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {children}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

function History() {
  const dispatch = useDispatch();
  const { calculation } = useSelector((state) => state);
  const { edit, history } = calculation;
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleLoadMore = () => {
    if (history.pagination.current_page === history.pagination.total_pages)
      return;
    dispatch(
      getCalculationLogs({
        id: edit.data.id_calculation,
        page: history.pagination.current_page + 1,
      })
    );
  };

  React.useEffect(() => {
    // if false then it is the first time the request is made
    if (!history.initial_load) {
      dispatch(getCalculationLogs({ id: edit.data.id_calculation }));
    }
  }, []);

  return (
    <Stack
      id={`history${edit.id_calculation}`}
      sx={{
        padding: [1, 2],
        overflowY: "auto",
        height: "100%",
        width: isSmallScreen ? 300 : 500,
      }}
      spacing={2}
    >
      <InfiniteScroll
        dataLength={history.results.length}
        next={handleLoadMore}
        hasMore={
          history.pagination.current_page != history.pagination.total_pages
        }
        scrollableTarget={`history${edit.id_calculation}`}
        endMessage={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "3em 0",
            }}
          >
            No hay mas por mostrar
          </Box>
        }
        loader={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "3em 0",
            }}
          >
            <CircularProgress
              sx={(theme) => ({ color: theme.palette.text.brand })}
            />
          </Box>
        }
      >
        {history.results.map((item, index) => (
          <React.Fragment key={`timeline_item_${index}`}>
            <Box sx={{ display: "flex", paddingBottom: 2 }}>
              <Box sx={{ color: "#BDBDBD" }}>
                <CircleIcon
                  size="small"
                  color="inherit"
                  sx={{ fontSize: 12 }}
                ></CircleIcon>
              </Box>
              <Stack
                spacing={1}
                sx={{
                  width: "100%",
                  paddingLeft: 1,
                  paddingTop: 0.2,
                  maxWidth: 500,
                }}
              >
                <Typography component={Box} sx={{ fontSize: 14 }}>
                  Modificado{" "}
                  {moment(item.change_date).calendar({
                    sameElse: "MMM Do Y,  h:mm A",
                  })}
                  , por{" "}
                  {item.changed_by ? (
                    <strong>{item.changed_by.username}</strong>
                  ) : (
                    <Typography
                      component={"i"}
                      sx={{ color: "#c2c2c2", fontSize: 14 }}
                    >
                      {item.changed_by_username}
                    </Typography>
                  )}
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{ th: { fontSize: 12 }, td: { fontSize: 12 } }}
                >
                  <Table size="small">
                    <TableBody>
                      {(item.changes.fields.length > 0 ||
                        Object.keys(item.changes.bottom_bearing).length > 0 ||
                        Object.keys(item.changes.bottom_sweep).length > 0) && (
                        <HistoryRow
                          collapse
                          cellsContent={["Cambios generales"]}
                        >
                          <ChangeRows data={item.changes.fields} />
                          {Object.keys(item.changes.bottom_sweep).length >
                            0 && (
                            <HistoryRow
                              reverseCollapse={
                                item.changes.bottom_sweep.fields.length > 0
                              }
                              collapse={
                                item.changes.bottom_sweep.fields.length > 0
                              }
                              cellsContent={[
                                "Barrido de fondo",
                                <Chip
                                  sx={{ borderRadius: 1, fontSize: 11 }}
                                  label={item.changes.bottom_sweep.type}
                                  size="small"
                                  color={
                                    change_types[item.changes.bottom_sweep.type]
                                  }
                                />,
                              ]}
                            >
                              {item.changes.bottom_sweep.fields.length > 0 && (
                                <ChangeRows
                                  data={item.changes.bottom_sweep.fields}
                                ></ChangeRows>
                              )}
                            </HistoryRow>
                          )}
                          {Object.keys(item.changes.bottom_bearing).length >
                            0 && (
                            <HistoryRow
                              reverseCollapse={
                                item.changes.bottom_bearing.fields.length > 0
                              }
                              collapse={
                                item.changes.bottom_bearing.fields.length > 0
                              }
                              cellsContent={[
                                "Cojinete de fondo",
                                <Chip
                                  sx={{ borderRadius: 1, fontSize: 11 }}
                                  label={item.changes.bottom_bearing.type}
                                  size="small"
                                  color={
                                    change_types[
                                      item.changes.bottom_bearing.type
                                    ]
                                  }
                                />,
                              ]}
                            >
                              {item.changes.bottom_bearing.fields.length >
                                0 && (
                                <ChangeRows
                                  data={item.changes.bottom_bearing.fields}
                                />
                              )}
                            </HistoryRow>
                          )}
                        </HistoryRow>
                      )}
                      {item.changes.propeller.length > 0 && (
                        <HistoryRow
                          collapse
                          cellsContent={["Cambios en las hélices"]}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Número</TableCell>
                                <TableCell>Tipo</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.changes.propeller.map((item, index) => {
                                let collapse = item.fields.length > 0;
                                return (
                                  <React.Fragment
                                    key={`history_row_propeller_${index}`}
                                  >
                                    <HistoryRow
                                      reverseCollapse={collapse}
                                      collapse={collapse}
                                      cellsContent={[
                                        item.id_propeller,
                                        item.propeller_number + 1,
                                        <Chip
                                          sx={{ borderRadius: 1, fontSize: 11 }}
                                          label={item.type}
                                          size="small"
                                          color={change_types[item.type]}
                                        />,
                                      ]}
                                    >
                                      {collapse && (
                                        <ChangeRows data={item.fields} />
                                      )}
                                    </HistoryRow>
                                  </React.Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </HistoryRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Box>
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </Stack>
  );
}

export default History;
