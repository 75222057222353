export const initialStateBottomBearing = (bottom_bearing_enabled = false) => {
  return {
    bottom_bearing: {
      name: "bottom_bearing",
      type: "",
      value: false,
    },
    bb_total_support_height: {
      name: "bb_total_support_height",
      type: "",
      value: "",
    },
    bearing_height: {
      name: "bearing_height",
      type: "",
      value: "",
    },
    height_to_bearing: {
      name: "height_to_bearing",
      type: "",
      value: "",
    },
    cutting: {
      name: "cutting",
      type: "",
      value: "",
    },
    bearing_reaction: {
      name: "bearing_reaction",
      type: "",
      value: "",
    },
    reaction_in_support: {
      name: "reaction_in_support",
      type: "",
      value: "",
    },
    support_moment: {
      name: "support_moment",
      type: "",
      value: "",
    },
    mobile_moment_n: {
      name: "mobile_moment_n",
      type: "",
      value: "",
    },
  };
};
