import { calculationInitialState } from "@/reducers/calculation.reducer";

export const makeCalculationRequest = (
  state,
  avoid_keys = [
    "table_detail",
    "table_comments",
    "loading",
    "error",
    "create",
    "edit",
    "message",
    "history",
  ]
) => {
  const request = {
    table_work_conditions: {},
    table_mixer_features: {},
    table_container_type: {},
    table_axis: {},
    table_propeller: [],
    table_bottom_sweep: {},
    table_bottom_bearing: {},
    table_gear_units: {},
    table_comments: "",
    table_detail: {},
  };

  // Son los estados que no necesito enviar al servidor
  // const avoid_keys = ["table_detail", "table_comments", "loading", "error", "message"];

  for (let key in state) {
    if (!avoid_keys.includes(key)) {
      let item = state[key];
      if (key === "table_propeller") {
        for (let i = 0; i < item.length; i++) {
          const propeller_item = {
            id_propeller: item[i].id_propeller,
            is_enabled: item[i].is_enabled,
            position: i,
            propeller_data: {},
          };
          for (let obj in item[i].data) {
            propeller_item["propeller_data"][obj] = item[i].data[obj].value;
            if (obj === "absorbed_power_calc") {
              propeller_item["propeller_data"][obj] = {
                value: item[i].data[obj].value,
                equals_on: item[i].data[obj].equals_on,
                multiplier: item[i].data[obj].multiplier,
              };
            }
          }
          request[key].push(propeller_item);
        }
      } else if (key == "table_detail" || key == "table_comments") {
        request[key] = state[key];
      } else {
        for (let obj in item) {
          request[key][obj] = item[obj].value;
        }
      }
    }
  }

  return request;
};

export const transformCalculationResponse = (response) => {
  let state = calculationInitialState();

  // Son los estados que no necesito enviar al servidor
  const avoid_keys = [
    "loading",
    "error",
    "edit",
    "create",
    "message",
    "history",
    // "table_comments",
    // "table_detail",
    // "table_gear_units",
  ];
  for (let key in state) {
    if (!avoid_keys.includes(key)) {
      if (key === "table_propeller") {
        let aux = { ...state[key][0] };
        state[key] = [];
        for (let index in response[key]) {
          let propeller = response[key][index];
          let propeller_item = {
            id: propeller.id_propeller,
            id_propeller: propeller.id_propeller,
            is_enabled: propeller.is_enabled,
            position: propeller.position,
            data: { ...aux.data },
          };
          for (let field in propeller.propeller_data) {
            if (field === "absorbed_power_calc") {
              propeller_item.data[field] = {
                ...propeller_item.data[field],
                value: propeller.propeller_data[field].value,
                equals_on: propeller.propeller_data[field].equals_on,
                multiplier: propeller.propeller_data[field].multiplier,
              };
              if (propeller.propeller_data[field].rounded_value) {
                propeller_item.data[field].rounded_value =
                  propeller.propeller_data[field].rounded_value;
              }
            } else {
              propeller_item.data[field] = {
                ...propeller_item.data[field],
                value: propeller.propeller_data[field].value,
              };
              if (propeller.propeller_data[field].rounded_value) {
                propeller_item.data[field]["rounded_value"] =
                  propeller.propeller_data[field].rounded_value;
              }
              if (propeller.propeller_data[field].message) {
                propeller_item.data[field]["message"] =
                  propeller.propeller_data[field].message;
              }
            }
          }
          state[key].push(propeller_item);
        }
        // for (let j in state[key]) {
        //   console.log(state[key][j].data.propeller_diameter);
        // }
        // state[key].sort((a, b) => {
        //   return a.position - b.position;
        // });
      } else if (key === "table_bottom_sweep") {
        state[key].bottom_sweep_enabled.value =
          response[key].bottom_sweep_enabled;
        for (let field in response[key].data) {
          if (field in state[key]) {
            state[key][field].value = response[key].data[field].value;
            if (response[key].data[field].rounded_value) {
              state[key][field].rounded_value =
                response[key].data[field].rounded_value;
            }
          }
        }
      } else if (key === "table_bottom_bearing") {
        state[key].bottom_bearing.value = response[key].bottom_bearing;
        for (let field in response[key].data) {
          if (field in state[key]) {
            state[key][field].value = response[key].data[field].value;
            if (response[key].data[field].rounded_value) {
              state[key][field].rounded_value =
                response[key].data[field].rounded_value;
            }
          }
        }
      } else if (key === "table_comments") {
        state[key] = response[key];
      } else if (key === "table_detail") {
        state[key]["detail_model"] = response[key]["detail_model"];
        state[key]["detail_total_cost"] = response[key]["detail_total_cost"];
        state[key]["details"] = response[key]["details"].filter((item) => {
          return item && item;
        });
        state[key]["details"].sort((a, b) => {
          return a.detail_position - b.detail_position;
        });
      } else if (key === "table_gear_units") {
        for (let field in response[key]) {
          state[key][field].value = response[key][field];
        }
      } else {
        for (let field in response[key]) {
          if (field in state[key]) {
            state[key][field].value = response[key][field].value;
            if (response[key][field].message) {
              state[key][field].message = response[key][field].message;
            }
            if (response[key][field].rounded_value) {
              state[key][field].rounded_value =
                response[key][field].rounded_value;
            }
          }
        }
      }
    }
  }
  state.edit.data = { ...response["table_calc_info"] };
  return state;
};
