export const initialStateBottomSweep = () => {
  return {
    bottom_sweep_enabled: {
      name: "bottom_sweep_enabled",
      value: false,
    },
    granulometry: {
      name: "granulometry",
      value: "",
    },
    liquid_density: {
      name: "liquid_density",
      value: "",
    },
    solid_density: {
      name: "solid_density",
      value: "",
    },
    mix_density: {
      name: "mix_density",
      value: "",
    },
    solid_concentrate_weight: {
      name: "solid_concentrate_weight",
      value: "",
    },
    solid_concentrate_volume: {
      name: "solid_concentrate_volume",
      value: "",
    },
    total_support_height: {
      name: "total_support_height",
      value: "",
    },
    h_d: {
      name: "h_d",
      value: "",
    },
    a: {
      name: "a",
      value: "",
    },
    s_m1_308: {
      name: "s_m1_308",
      value: "",
    },
    b_m1_309: {
      name: "b_m1_309",
      value: "",
    },
    b: {
      name: "b",
      value: "",
    },
    b_m1_311: {
      name: "b_m1_311",
      value: "",
    },
    equal_number_of_propellers: {
      name: "equal_number_of_propellers",
      value: "",
    },
    bs_flow_speed: {
      name: "bs_flow_speed",
      value: "",
    },
    h0_d0: {
      name: "h0_d0",
      value: "",
    },
    homogenized_height: {
      name: "homogenized_height",
      value: "",
    },

    decantation_height: {
      name: "decantation_height",
      value: "",
    },
    decanted_volume: {
      name: "decanted_volume",
      value: "",
    },
    decanted_weight: {
      name: "decanted_weight",
      value: "",
    },
    decanted_density: {
      name: "decanted_density",
      value: "",
    },
    absorbed_power_start: {
      name: "absorbed_power_start",
      value: "",
    },
    comments: [],
  };
};
