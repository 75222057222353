import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Layout from "@/components/Layout";
import {
  TableWorkConditions,
  TableMixerFeatures,
  TableContainerType,
  TableDetail,
  TableBottomSweep,
  TableAxis,
  TablePropeller,
  TableGearUnits,
  TableComments,
} from "@/components/CalcTables";
import MessageAlert from "@/components/MessageAlert";
import History from "@/components/History";
import DrawerHeader from "@/components/DrawerHeader";

import { useDispatch, useSelector } from "react-redux";
import { calculation_constants } from "@/constants/calculation.constants";
import {
  getCalculationById,
  saveCalculation,
} from "@/actions/calculation.actions";

import { Box, Grid, useMediaQuery, Stack, Typography } from "@mui/material";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import SpeedDial from "@mui/material/SpeedDial";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SaveIcon from "@mui/icons-material/Save";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import useIsAuth from "@/hooks/useIsAuth";

import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CalculationPage = () => {
  useIsAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [showGearUnits, setShowGearUnits] = React.useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = React.useState(false);
  const [isDownload, setIsDownload] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);

  const { id } = params;
  const { calculation } = useSelector((state) => state);
  const { edit, create } = calculation;

  const handleCloseSaveConfirmation = () => {
    setShowSaveConfirmation(false);
    if (isDownload) {
      setIsDownload(false);
    }
  };
  const handleClickOpenSaveConfirmation = (download = false) => {
    setShowSaveConfirmation(true);
    if (download) {
      setIsDownload(true);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowHistory(open);
  };

  React.useEffect(() => {
    if (id) {
      dispatch(getCalculationById({ id }));
    }
  }, [id]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: calculation_constants.RESET_CALCULATION });
    };
  }, [location]);

  return (
    <Layout>
      <MessageAlert />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={edit.loading || create.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {calculation.edit.is_edit && (
        <Box sx={{ padding: "10px 0" }}>
          <IconButton
            size="medium"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack fontSize="medium"></ArrowBack>
          </IconButton>
          <IconButton onClick={toggleDrawer(true)}>
            <HistoryIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={showHistory}
            onClose={toggleDrawer(false)}
          >
            <Stack
              sx={{
                overflow: "hidden",
              }}
            >
              <DrawerHeader />
              <Stack sx={{ padding: [1, 2] }} spacing={1}>
                <Typography component={Box} sx={{ fontSize: 28 }}>
                  Historial
                </Typography>
                <Box>
                  <Typography variant="caption">Creado por</Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                    {edit.data?.user?.username}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">Fecha de creación</Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                    {moment(edit.data.create_time).calendar({
                      sameElse: "MMM Do Y,  h:mm A",
                    })}
                  </Typography>
                </Box>
              </Stack>
              <History />
              <Box sx={{ width: "100%", height: "10%" }}></Box>
            </Stack>
          </Drawer>
        </Box>
      )}
      <Box sx={{ flexGrow: 1, maxWidth: 2200 }}>
        <Grid container spacing={1.5}>
          <Grid
            item
            container
            md={12}
            lg={7}
            xl={6}
            spacing={1.5}
            sx={{ height: "min-content" }}
          >
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TableWorkConditions />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TableMixerFeatures />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TableContainerType />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <TablePropeller />
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <TableAxis />
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={12}
            lg={5}
            xl={6}
            spacing={1.5}
            sx={{ height: "min-content" }}
          >
            <Grid item xs={12} sm={12} md={8} lg={12} xl={8}>
              <TableDetail />
            </Grid>
            <Grid item xs={12} sm={8} md={4} lg={8} xl={4}>
              <TableBottomSweep />
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={12} xl={8}>
              <TableComments />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        fullScreen
        // maxWidth="lg"
        open={showGearUnits}
        onClose={() => {
          setShowGearUnits(false);
        }}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setShowGearUnits(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <TableGearUnits />
      </Dialog>

      <Dialog
        open={showSaveConfirmation}
        onClose={handleCloseSaveConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {calculation.edit.is_edit ? (
            <>Actualizar el cálculo</>
          ) : (
            <>Guardar el cálculo</>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {calculation.edit.is_edit ? (
              <>¿Estás seguro de actualizar este cálculo?</>
            ) : (
              <>¿Estás seguro que deseas guardar el cálculo?</>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveConfirmation}>Cancelar</Button>
          <Button
            onClick={() => {
              dispatch(saveCalculation(isDownload));
              handleCloseSaveConfirmation();
            }}
            color="success"
            autoFocus
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <SpeedDial
        ariaLabel="Calculation actions"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
        icon={<MoreVertIcon />}
      >
        <SpeedDialAction
          onClick={() => handleClickOpenSaveConfirmation(true)}
          key={"save-download-pdf"}
          icon={<PictureAsPdfIcon />}
          tooltipTitle={"Guardar y descargar PDF"}
        />
        <SpeedDialAction
          onClick={() => handleClickOpenSaveConfirmation()}
          key={"save"}
          icon={<SaveIcon />}
          tooltipTitle={"Guardar"}
        />
        {!edit.is_edit && (
          <SpeedDialAction
            onClick={() => {
              dispatch({ type: calculation_constants.RESET_CALCULATION });
            }}
            key={"Reiniciar"}
            icon={<RestartAltIcon />}
            tooltipTitle={"Reiniciar"}
          />
        )}
        <SpeedDialAction
          onClick={() => {
            setShowGearUnits(true);
          }}
          key={"Reductores"}
          icon={<PermDataSettingIcon />}
          tooltipTitle={"Reductores"}
        />
      </SpeedDial>
    </Layout>
  );
};
export default CalculationPage;
