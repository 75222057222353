import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";

import Grid from "@mui/material/Grid";
import CalcInput from "@/components/CalcInput";

import { getPropellerContent } from "@/helpers/tables_content/propellers_content";
import {
  AddPropellerIcon,
  PropellerHeadIcons,
} from "@/components/InteractiveIcons/PropellerHeadIcons";

import useChangeTable from "@/hooks/useChangeTable";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateChange,
  onMobileTypeChange,
} from "@/actions/calculation.actions";
import getClassDependsOn from "@/helpers/getClassDependsOn";

const TablePropeller = () => {
  const rows = getPropellerContent();
  const ref = React.useRef();
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.calculation);
  const { table_propeller, error } = calculation;

  const handleChange = (e) => {
    if (e.target.name === "mobile_type") {
      dispatch(
        onMobileTypeChange({
          position: e.target.dataset.indexNumber,
          value: e.target.value,
        })
      );
    } else if (e.data) {
      dispatch(
        calculateChange({
          belongs_to: "table_propeller",
          name: e.data.name,
          position: e.data.indexNumber,
          value: e.data.value,
        })
      );
    } else {
      dispatch(
        calculateChange({
          belongs_to: "table_propeller",
          name: e.target.name,
          position: e.target.dataset.indexNumber,
          value: e.target.value,
        })
      );
    }
  };

  useChangeTable({
    ref,
    handleChange,
  });

  return (
    <Grid container>
      <Grid item xs={11}>
        <TableContainer>
          <Table aria-label="table propeller">
            <TableHead sx={{ padding: 0 }}>
              <TableRow
                sx={(theme) => ({
                  position: "relative",
                  zIndex: theme.zIndex.appBar + 2,
                  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)",
                })}
              >
                <TableCell
                  sx={(theme) => ({
                    minWidth: 125,
                    padding: "0 10px",
                    position: "sticky",
                    left: 0,
                    zIndex: theme.zIndex.appBar + 1,
                    backgroundColor: "white",
                  })}
                >
                  <span>Hélice</span>
                </TableCell>
                {table_propeller.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <PropellerHeadIcons number={index} id={item.id} />
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody ref={ref} id="table_propeller_body">
              {rows.map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        padding="none"
                        sx={(theme) => ({
                          padding: "0 10px",
                          position: "sticky",
                          left: 0,
                          zIndex: theme.zIndex.appBar,
                          backgroundColor: "white",
                          borderRight: "2px solid rgba(0,0,0,0.1)",
                        })}
                      >
                        <span>{row.label}</span>
                      </TableCell>
                      {table_propeller.map((column) => {
                        let is_enabled = column.data[row.name].is_enabled;
                        let read_only = row.read_only;
                        let origin_class = row.class;
                        let field_value = column.data[row.name].value;
                        let message =
                          column.data[row.name].message &&
                          column.data[row.name].message;
                        if (column.data[row.name].rounded_value) {
                          field_value = column.data[row.name].rounded_value;
                        }
                        let props = {};
                        if (row.type === "CUSTOM") {
                          props = {
                            message:
                              column.data[row.name].message &&
                              column.data[row.name].message,
                            class: getClassDependsOn(
                              error.errors_in,
                              row.name,
                              is_enabled,
                              origin_class,
                              column.position
                            ),
                            is_enabled: is_enabled,
                            name: row.name,
                            index: column.position,
                            state_value: field_value,
                          };
                          if (row.name === "absorbed_power_calc") {
                            props.equals_on = column.data[row.name].equals_on;
                            props.multiplier = column.data[row.name].multiplier;
                          }
                        }
                        return (
                          <React.Fragment key={column.id}>
                            <TableCell
                              padding="none"
                              sx={{
                                ...getClassDependsOn(
                                  error.errors_in,
                                  row.name,
                                  is_enabled,
                                  origin_class,
                                  column.position
                                ),
                              }}
                            >
                              {row.type === "CUSTOM" ? (
                                <>{row.getContent(props)}</>
                              ) : (
                                <CalcInput
                                  componentsProps={{
                                    input: {
                                      "data-index-number": column.position,
                                    },
                                  }}
                                  disabled={
                                    !is_enabled
                                      ? true
                                      : read_only
                                      ? true
                                      : false
                                  }
                                  readOnly={
                                    !is_enabled
                                      ? true
                                      : read_only
                                      ? true
                                      : false
                                  }
                                  name={row.name}
                                  state_value={field_value}
                                  message={message}
                                />
                              )}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid xs={1} item sx={{ width: "min-content !important" }}>
        <AddPropellerIcon></AddPropellerIcon>
      </Grid>
    </Grid>
  );
};

export default TablePropeller;
