export const inputOnly = {
  backgroundColor: "transparent",
};
export const outputOnly = {
  color: "#272727",
  backgroundColor: "#d5d5d5",
};
export const inputOutputOnly = {
  color: "#000019",
  backgroundColor: "#d4eefa",
};
export const inputError = {
  color: "#ae0000",
  backgroundColor: "#ffcccc",
};

export const disabledOnly = {
  color: "#101010",
  backgroundColor: "#555555",
};
