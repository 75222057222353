import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function PdfDisplayer() {
  const reports = useSelector((state) => state.reports);
  const { loading_pdf } = reports;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 20,
      }}
    >
      {loading_pdf && <CircularProgress></CircularProgress>}
      <Box
        id="pdf_preview_container"
        sx={{
          width: "100%",
          height: "750px",
          object: { width: "100%", height: "100%" },
          "@media (max-width: 800px)": {
            height: "500px",
          },
        }}
      >
        {/* <object
        className="reports__pdf-preview"
        title="pdf"
        name="pdf_report_displayed"
        type="application/pdf"
      ></object> */}
      </Box>
    </Box>
  );
}

export default PdfDisplayer;
