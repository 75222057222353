import React from "react";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { getPDFPreview, getPDFDownload } from "@/actions/reports.actions";
import { deleteCalculation } from "@/actions/reports.actions";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function MenuActions(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const pdfPreviewHanlder = (e) => {
    props.onClose();
    dispatch(getPDFPreview(props.id_calculation));
  };
  const pdfDownloadHanlder = (e) => {
    props.onClose();
    dispatch(getPDFDownload(props.id_calculation));
  };

  const handleDelete = () => {
    dispatch(deleteCalculation(props.id_calculation));
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Estás seguro que deseas eliminar este cálculo?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta acción es irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Menu {...props}>
        <MenuItem onClick={pdfPreviewHanlder}>Preview pdf</MenuItem>
        <MenuItem onClick={pdfDownloadHanlder}>Descargar pdf</MenuItem>
        {/* <MenuItem onClick={props.onClose}>Ver pdf</MenuItem> */}
        <MenuItem
          onClick={props.onClose}
          sx={(theme) => ({
            a: {
              textDecoration: "none",
              color: theme.palette.text.primary,
              width: "100%",
            },
          })}
        >
          <Link to={`/calculation/${props.id_calculation}`}>Editar</Link>
        </MenuItem>
        <MenuItem onClick={handleClickOpen}>Eliminar</MenuItem>
      </Menu>
    </>
  );
}

export default MenuActions;
