import React from "react";
import { inputOnly, outputOnly } from "../input_classes";
export const getBottomSweepContent = () => {
  return [
    {
      name: "granulometry",
      label: "Granulometría mm",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      label: (
        <>
          Densidad líquido kg/m<sup>3</sup>
        </>
      ),
      name: "liquid_density",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      label: (
        <>
          Densidad sólido kg/m<sup>3</sup>
        </>
      ),
      name: "solid_density",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      label: (
        <>
          Densidad mezcla kg/m<sup>3</sup>
        </>
      ),
      name: "mix_density",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Concentr.sólido en peso %/100",
      name: "solid_concentrate_weight",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Concentr.sólido en vol %/100",
      name: "solid_concentrate_volume",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Altura total desde apoyo m",
      name: "total_support_height",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      label: "h/D",
      name: "h_d",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "A",
      name: "a",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "S M1-308",
      name: "s_m1_308",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "B M1-309",
      name: "b_m1_309",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "B",
      name: "b",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "B M1-311 (En caso de ß>1)",
      name: "b_m1_311",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "k - nº de hélices iguales",
      name: "equal_number_of_propellers",
      type: "",
      class: inputOnly,
      read_only: false,
    },
    {
      label: "Vf0 m/s",
      name: "bs_flow_speed",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Altura homogeneizada",
      name: "",
      type: "HEADER",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "(Hu/D)0 M1-314",
      name: "h0_d0",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Con Vf real, alt. homogeneizada",
      name: "homogenized_height",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Altura del decantado",
      name: "",
      type: "HEADER",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Altura de la decantación m",
      name: "decantation_height",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: (
        <>
          Volumen decantado m<sup>3</sup>
        </>
      ),
      name: "decanted_volume",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Peso decantado kg",
      name: "decanted_weight",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: (
        <>
          Densidad decantado kg/m<sup>3</sup>
        </>
      ),
      name: "decanted_density",
      type: "",
      class: outputOnly,
      read_only: true,
    },
    {
      label: "Pot absorbida arranque kw",
      name: "absorbed_power_start",
      type: "",
      class: outputOnly,
      read_only: true,
    },
  ];
};
