import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/Table";
import CalcInput from "@/components/CalcInput";
import useChangeTable from "@/hooks/useChangeTable";
import { useDispatch, useSelector } from "react-redux";
import { calculateChange } from "@/actions/calculation.actions";
import getClassDependsOn from "@/helpers/getClassDependsOn";
import { getWorkConditionsContent } from "@/helpers/tables_content/work_conditions";

function TableWorkConditions() {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.calculation);
  const { table_work_conditions, error } = calculation;

  const handleChange = (e) => {
    dispatch(
      calculateChange({
        belongs_to: "table_work_conditions",
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  useChangeTable({ ref, handleChange });

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="table work conditions">
        <TableBody ref={ref}>
          {getWorkConditionsContent().map((item) => (
            <React.Fragment key={item.name}>
              <TableRow>
                <TableCell padding="none" sx={{ padding: "0 10px" }}>
                  <span>{item.label}</span>
                </TableCell>
                <TableCell padding="none" align="right">
                  <CalcInput
                    name={item.name}
                    sx={getClassDependsOn(error.errors_in, item.name, true, {})}
                    state_value={table_work_conditions[item.name].value}
                  ></CalcInput>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableWorkConditions;
