import * as React from "react";
import InputUnstyled from "@mui/base/InputUnstyled";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";

const StyledInputElement = styled("div")(
  ({ theme }) => `
  width: 100%;
  font-size: inherit;
  display: flex;
  input {
    width: 100%;
    font-size: inherit;
    color: inherit;
    border: none;
    background: inherit;
    outline: none;
    transition: all 100ms ease;
    border: 2px solid transparent;
    &:focus {
        border: 2px solid ${
          theme.palette.mode === "dark" ? theme.palette.text.primary : "black"
        };
    }
  }
  `
);

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  return (
    <InputUnstyled
      autoComplete="off"
      components={{
        Root: StyledInputElement,
        // Input: StyledInputElement,
      }}
      // If message props exists we are going to show an alert with the message
      endAdornment={
        props.message && (
          <Tooltip title={props.message}>
            <WarningIcon fontSize="small" style={{ color: "#fbae1f" }} />
          </Tooltip>
        )
      }
      {...props}
      ref={ref}
    />
  );
});

export default React.forwardRef(function CalcInput(props, ref) {
  const [value, setValue] = React.useState(
    props.state_value ? props.state_value : ""
  );
  React.useEffect(() => {
    setValue(props.state_value); // Need to fix this react issue
  }, [props.state_value]);

  return (
    <CustomInput
      ref={ref}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      {...props}
    />
  );
});
