import React from "react";

/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  addPropeller,
  deletePropeller,
  disablePropeller,
  enablePropeller,
  duplicatePropeller,
} from "@/actions/calculation.actions";
import { TableCell } from "@/components/Table";

export function PropellerHeadIcons({ number, id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.calculation);
  const { table_propeller } = calculation;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TableCell
      sx={{
        minWidth: 90,
        backgroundColor: !table_propeller[number].is_enabled && "#e3e3e3",
        transition: "background-color ease-in-out .5s",
      }}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            flex-grow: 1;
            display: flex;
            align-items: center;
          `}
        >
          <span>{number + 1}</span>
        </div>
        <div
          css={css`
            position: relative;
            right: 2;
          `}
        >
          <div>
            <IconButton
              color="inherit"
              size="medium"
              onClick={handlePopoverOpen}
            >
              <MoreVertIcon style={{ fontSize: 14 }} />
            </IconButton>
            <IconButton
              color="inherit"
              size="medium"
              onClick={() => {
                dispatch(deletePropeller(id));
              }}
            >
              <DeleteIcon style={{ fontSize: 14 }} />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
            >
              <Box sx={{ padding: "5px" }}>
                <Tooltip
                  title={
                    table_propeller[number].is_enabled
                      ? "Desactivar"
                      : "Activar"
                  }
                  placement="top"
                >
                  <IconButton
                    color="inherit"
                    size="medium"
                    onClick={() => {
                      if (table_propeller[number].is_enabled) {
                        dispatch(disablePropeller(number));
                      } else {
                        dispatch(enablePropeller(number));
                      }
                    }}
                  >
                    <VisibilityIcon
                      sx={(theme) => ({
                        fontSize: 13,
                        color: table_propeller[number].is_enabled
                          ? theme.palette.text.brand
                          : "#d5d5d5",
                      })}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Duplicar" placement="top">
                  <IconButton
                    color="inherit"
                    size="medium"
                    onClick={() => {
                      dispatch(duplicatePropeller(number));
                    }}
                  >
                    <ContentPasteIcon
                      sx={(theme) => ({
                        fontSize: 13,
                        color: theme.palette.text.brand,
                      })}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Popover>
          </div>
        </div>
      </div>
    </TableCell>
  );
}

export function AddPropellerIcon() {
  const dispatch = useDispatch();
  return (
    <div>
      <IconButton
        onClick={() => {
          dispatch(addPropeller());
        }}
        color="inherit"
        sx={{ color: "inherit", padding: 0 }}
        size="large"
      >
        <AddBoxIcon />
      </IconButton>
    </div>
  );
}
