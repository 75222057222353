import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
/** @jsxImportSource @emotion/react */ import {
  css,
  jsx,
} from "@emotion/react";

import DrawerHeader from "@/components/DrawerHeader";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { user_constants } from "@/constants/user.constants";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "black",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ModalAccountActivated = () => {
  const { user_sign_in } = useSelector((state) => state);
  // const [open, setOpen] = React.useState(true);
  const { show_account_not_activated } = user_sign_in;
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleCloseModal = () => {
  //   dispatch({
  //     type: user_constants.SHOW_ACCOUNT_NOT_ACTIVATED,
  //     payload: false,
  //   });
  // };
  return (
    <Dialog
      open={show_account_not_activated}
      // onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Activación necesaria"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          La activación de tu cuenta es necesaria. Para poder activarla es
          necesario cambiar tu contraseña.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            // handleCloseModal();
            navigate("/account");
          }}
        >
          Ir a mi cuenta
        </Button>
        {/* <Button onClick={handleCloseModal} autoFocus>
          Cerrar
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default function Layout({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { user_sign_in } = useSelector((state) => state);
  const { show_account_not_activated } = user_sign_in;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {show_account_not_activated === true &&
        location.pathname !== "/account" &&
        location.pathname !== "/account/password-reset" && (
          <ModalAccountActivated />
        )}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Autmix |{" "}
            <Typography component="span" sx={{ color: "#c5c5c5" }}>
              Mezcal
            </Typography>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link
            css={css`
              text-decoration: none;
              color: inherit;
            `}
            to="/account"
          >
            <ListItem button key="account">
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Cuenta" />
            </ListItem>
          </Link>
          <Link
            css={css`
              text-decoration: none;
              color: inherit;
            `}
            to="/"
          >
            <ListItem button key="calculations">
              <ListItemIcon>
                <CalculateIcon />
              </ListItemIcon>
              <ListItemText primary="Cálculos" />
            </ListItem>
          </Link>
          <Link
            css={css`
              text-decoration: none;
              color: inherit;
            `}
            to="/reports"
          >
            <ListItem button key="reports">
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: "hidden" }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
