import React from "react";
import Layout from "@/components/Layout";
import ReportsTable from "@/components/ReportsTable";
import ReportsFilter from "@/components/ReportsFilter";
import PdfDisplayer from "@/components/PdfDisplayer";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "@/actions/reports.actions";
import { useLocation } from "react-router-dom";
import useIsAuth from "@/hooks/useIsAuth";
const ReportsPage = () => {
  useIsAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const user_sign_in = useSelector((state) => state.user_sign_in);
  const { user_info } = user_sign_in;

  React.useEffect(() => {
    dispatch(getReports({ query: location.search }));
  }, [location]);

  return (
    <Layout>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 1200,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          {user_info && (
            <>
              <ReportsFilter />
              <ReportsTable></ReportsTable>
              <PdfDisplayer />
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default ReportsPage;
