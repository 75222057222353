export const calculation_constants = {
  RESET_CALCULATION: "RESET_CALCULATION",

  // Calculation interactions
  ADD_PROPELLER: "ADD_PROPELLER",
  DELETE_PROPELLER: "DELETE_PROPELLER",

  DISABLE_PROPELLER: "DISABLE_PROPELLER",
  ENABLE_PROPELLER: "ENABLE_PROPELLER",

  DUPLICATE_PROPELLER: "DUPLICATE_PROPELLER",
  // -------------

  CHANGE_CONTAINER_TYPE: "CHANGE_CONTAINER_TYPE",
  BOTTOM_SWEEP_ENABLED: "BOTTOM_SWEEP_ENABLED",
  BOTTOM_BEARING_ENABLED: "BOTTOM_BEARING_ENABLED",

  ON_MOBILE_TYPE_CHANGE: "ON_MOBILE_TYPE_CHANGE",

  SET_CALCULATION_COMMENTS: "SET_CALCULATION_COMMENTS",

  SET_DETAIL_PROP: "SET_DETAIL_PROP",
  SET_DETAIL: "SET_DETAIL",

  // Calculate request actions
  CALCULATE_CHANGE_REQUEST: "CALCULATE_CHANGE_REQUEST",
  CALCULATE_CHANGE_REQUEST_SUCCESS: "CALCULATE_CHANGE_REQUEST_SUCCESS",
  CALCULATE_CHANGE_REQUEST_FAIL: "CALCULATE_CHANGE_REQUEST_FAIL",
  // -------------

  // On axis diameter, axist thickness and axis type, change.
  ON_AD_ATH_AT_CHANGE: "ON_AD_ATH_AT_CHANGE",
  // -------------

  // Start a new calc from another
  NEW_CALC_FROM_ANOTHER: "NEW_CALC_FROM_ANOTHER",
  // -------------

  // Set the data for edit
  CALCULATION_BY_ID_REQUEST: "CALCULATION_BY_ID_REQUEST",
  CALCULATION_BY_ID_REQUEST_SUCCESS: "CALCULATION_BY_ID_REQUEST_SUCCESS",
  CALCULATION_BY_ID_REQUEST_FAIL: "CALCULATION_BY_ID_REQUEST_FAIL",
  // -------------

  CALCULATION_CREATE_REQUEST: "CALCULATION_CREATE_REQUEST",
  CALCULATION_CREATE_REQUEST_SUCCESS: "CALCULATION_CREATE_REQUEST_SUCCESS",
  CALCULATION_CREATE_REQUEST_FAIL: "CALCULATION_CREATE_REQUEST_FAIL",

  CALCULATION_UPDATE_REQUEST: "CALCULATION_UPDATE_REQUEST",
  CALCULATION_UPDATE_REQUEST_SUCCESS: "CALCULATION_UPDATE_REQUEST_SUCCESS",
  CALCULATION_UPDATE_REQUEST_FAIL: "CALCULATION_UPDATE_REQUEST_FAIL",

  CALCULATION_DELETE_REQUEST: "CALCULATION_DELETE_REQUEST",
  CALCULATION_DELETE_REQUEST_SUCCESS: "CALCULATION_DELETE_REQUEST_SUCCESS",
  CALCULATION_DELETE_REQUEST_FAIL: "CALCULATION_DELETE_REQUEST_FAIL",

  // Messages handler
  SET_MESSAGE: "CALCULATION_SET_MESSAGE",
  HIDE_MESSAGE: "CALCULATION_HIDE_MESSAGE",
  // CLEAR_MESSAGES: "CALCULATION_CLEAR_MESSAGES",

  // Load the change log for one calc
  CALCULATION_CHANGE_LOG_REQUEST: "CALCULATION_CHANGE_LOG_REQUEST",
  CALCULATION_CHANGE_LOG_REQUEST_SUCCESS:
    "CALCULATION_CHANGE_LOG_REQUEST_SUCCESS",
  CALCULATION_CHANGE_LOG_REQUEST_FAIL: "CALCULATION_CHANGE_LOG_REQUEST_FAIL",
};
