import axios_auth from "../middlewares/refresh.middleware";
export const getReportsService = async (access, query) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      timeout: 5000,
    };
    const { data } = await axios_auth.get(
      `${process.env.REACT_APP_API_URL}/1.0/calculations/${query}`,
      config
    );

    return { data: data, success: true };
  } catch (error) {
    if (error.response) {
      return { data: error.response.data, success: false };
    }
    return { data: { message: "Something went wrong", success: false } };
  }
};
