export const getGroupOfMobileTypeChange = ({ new_value, last_value }) => {
  const group = identifyGroupOfMobileType(new_value);

  if (!group) {
    return null;
  }
  if (last_value === "Ninguno") {
    let aux = group.items_enabled.concat(outputs);
    group.items_enabled = aux;
  } else if (new_value === "Ninguno") {
    let aux = group.items_disabled.concat(outputs);
    group.items_disabled = aux;
  }

  if (!group["items"].includes(last_value)) {
    // Para que no se vuelva a renderizar si el anterior y el nuevo
    // desbloquean lo mismo.
    return {
      items_enabled: group["items_enabled"],
      items_disabled: group["items_disabled"],
    };
  }

  return false;
};

const outputs = [
  "d_d",
  "absorbed_power",
  "axial_flow",
  "renovations_per_hour",
  "flow_speed",
  "peripheral_speed",
  "reynolds_number",
  "intensity",
  "corrected_intensity",
  "propeller_thickness",
  "propeller_weight",
  "radial_force",
  "axial_force",
];

const identifyGroupOfMobileType = (mobile_type) => {
  const groups = {
    group_one: {
      items: ["Gamma", "Stokes", "Nabla", "Sabre"],
      items_enabled: [
        "propeller_diameter",
        "number_of_blades",
        "flow_direction",
        "absorbed_power_calc",
        "real_thickness",
        "core_weight",
        "length_to_flange",
      ],
      items_disabled: ["blade_inclination", "blade_width", "blade_height"],
    },
    group_two: {
      items: ["U", "V", "Ancla"],
      items_enabled: [
        "propeller_diameter",
        "blade_width",
        "blade_height",
        "absorbed_power_calc",
        "real_thickness",
        "core_weight",
        "length_to_flange",
      ],
      items_disabled: [
        "number_of_blades",
        "blade_inclination",
        "flow_direction",
      ],
    },
    group_three: {
      items: ["Ninguno"],
      items_enabled: [],
      items_disabled: [
        "propeller_diameter",
        "number_of_blades",
        "blade_inclination",
        "blade_width",
        "blade_height",
        "flow_direction",
        "absorbed_power_calc",
        "real_thickness",
        "core_weight",
        "length_to_flange",
      ],
    },
    group_four: {
      items: ["Turbina"],
      items_enabled: [
        "propeller_diameter",
        "number_of_blades",
        "blade_inclination",
        "blade_width",
        "blade_height",
        "flow_direction",
        "absorbed_power_calc",
        "real_thickness",
        "core_weight",
        "length_to_flange",
      ],
      items_disabled: [],
    },
    group_five: {
      items: ["Cowles", "Rot. Estat"],
      items_enabled: [
        "propeller_diameter",
        "absorbed_power_calc",
        "real_thickness",
        "core_weight",
        "length_to_flange",
      ],
      items_disabled: [
        "number_of_blades",
        "blade_inclination",
        "blade_width",
        "blade_height",
        "flow_direction",
      ],
    },
    group_six: {
      items: ["Doble flujo"],
      items_enabled: [
        "propeller_diameter",
        "flow_direction",
        "absorbed_power_calc",
        "real_thickness",
        "core_weight",
        "length_to_flange",
      ],
      items_disabled: [
        "number_of_blades",
        "blade_inclination",
        "blade_width",
        "blade_height",
      ],
    },
  };

  for (let group in groups) {
    if (groups[group].items.includes(mobile_type)) {
      return groups[group];
    }
  }
  return false;
};
